import ConfigColorsList from 'Reference/pages/ConfigColors/ConfigColorsList/ConfigColorsList.vue'
import ConfigColorsEdit from 'Reference/pages/ConfigColors/ConfigColorsEdit/ConfigColorsEdit.vue'
import ConfigColorsCreate from 'Reference/pages/ConfigColors/ConfigColorsCreate/ConfigColorsCreate.vue'

import ConfigPlatformsList from 'Reference/pages/ConfigPlatforms/ConfigPlatformsList/ConfigPlatformsList.vue'

import ConfigContactList from 'Reference/pages/ConfigContact/ConfigContactList/ConfigContactList.vue'
import ConfigContactEdit from 'Reference/pages/ConfigContact/ConfigContactEdit/ConfigContactEdit.vue'
import ConfigContactCreate from 'Reference/pages/ConfigContact/ConfigContactCreate/ConfigContactCreate.vue'

import ConfigPaymentList from 'Reference/pages/ConfigPayment/ConfigPaymentList/ConfigPaymentList.vue'
import ConfigPaymentEdit from 'Reference/pages/ConfigPayment/ConfigPaymentEdit/ConfigPaymentEdit.vue'
import ConfigPaymentCreate from 'Reference/pages/ConfigPayment/ConfigPaymentCreate/ConfigPaymentCreate.vue'

export default [
  {
    path: '/config-colors',
    name: 'ConfigColorsList',
    component: ConfigColorsList,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-view'],
      },
      search: {
        name: 'Настройка цветов',
        icon: 'picture',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/config-colors/create',
    name: 'ConfigColorsCreate',
    component: ConfigColorsCreate,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-create'],
      },
      search: {
        name: 'Создать цвет',
        icon: 'picture',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/config-colors/:color_id',
    name: 'ConfigColorsEdit',
    component: ConfigColorsEdit,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/config-contacts',
    name: 'ConfigContactList',
    component: ConfigContactList,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-view'],
      },
      search: {
        name: 'Настройка способов связи',
        icon: 'contacts',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/config-contacts/create',
    name: 'ConfigContactCreate',
    component: ConfigContactCreate,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-create'],
      },
      search: {
        name: 'Создать способ связи',
        icon: 'contacts',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/config-contacts/:contact_id',
    name: 'ConfigContactEdit',
    component: ConfigContactEdit,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/config-platforms',
    name: 'ConfigPlatformsList',
    component: ConfigPlatformsList,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-view'],
      },
      search: {
        name: 'Список платформ',
        icon: 'picture',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/config-payment',
    name: 'ConfigPaymentList',
    component: ConfigPaymentList,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-view'],
      },
      search: {
        name: 'Настройка оплаты',
        icon: 'credit-card',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/config-payment/create',
    name: 'ConfigPaymentCreate',
    component: ConfigPaymentCreate,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-create'],
      },
      search: {
        name: 'Создать способ оплаты',
        icon: 'credit-card',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/config-payment/:payment_id',
    name: 'ConfigPaymentEdit',
    component: ConfigPaymentEdit,
    meta: {
      // temporary priviliges
      permission: {
        service: 'banner',
        permissions: ['banner-update'],
      },
      failPath: '/censor',
    },
  },
]
