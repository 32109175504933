<template>
  <div>
    <a-button
      class="logger"
      @click="loggerToggle"
      type="primary"
      icon="robot"
    />
    <a-drawer
      title="Последние действия(Логи)"
      :width="720"
      placement="right"
      :closable="true"
      :visible="visible"
      @close="loggerToggle"
    >
      <a-button-group>
        <a-button v-clipboard:copy="JSON.stringify(logList)">
          <a-icon type="copy" />Скопировать все логи
        </a-button>
        <a-button @click="downloadJson">
          <a-icon type="download" />скачать логи
        </a-button>
      </a-button-group>

      <div class="actions">
        <h3>
          Ваш браузер определен как:
          <b>{{ logList.userContext.browserName }}</b>
        </h3>
        <div>
          <div v-for="(action, index) in logList.actionList" :key="index">
            <div v-if="action.to || action.from" class="actions_label">
              Переход из
              <a-tag color="orange">
                {{ action.from }}
              </a-tag>
              в
              <a-tag color="darkgreen">
                {{ action.to }}
              </a-tag>
            </div>
            <div v-else class="actions_label">
              <a-tag :color="getColor(action.type)">
                {{ action.type }}
              </a-tag>
              {{ action.path }}
            </div>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { downloadJson } from 'Core/utils/downloadJson.ts'
export default {
  name: 'Logger',
  data: function () {
    return {
      visible: false,
      logList: this.$logger,
    }
  },
  methods: {
    loggerToggle() {
      this.visible = !this.visible
    },
    getColor(type) {
      switch (type) {
        case 'POST':
        case 'PATCH':
        case 'PUT': {
          return 'blue'
        }
        case 'DELETE': {
          return 'darkred'
        }
        case 'GET': {
          return 'darkgreen'
        }
        default: {
          return 'green'
        }
      }
    },
    downloadJson() {
      const date = this.$options.filterss.formatDate(new Date())
      downloadJson('Логи ' + date, JSON.stringify(this.logList))
    },
  },
}
</script>

<style scoped lang="scss">
.actions {
  margin-top: 10px;
  &_label {
    padding: 5px 12px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
  }
}
</style>
