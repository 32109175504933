<template>
  <div>
    <a-spin :spinning="isFetching">
      <a-descriptions v-show="!isFetching">
        <template v-slot:title>
          <div class="settings-wrap">
            <h2>
              {{ formState.store_name }} <code>#{{ merchantID }}</code>
            </h2>
            <a-button @click="() => $router.push({ name: 'MerchantList' })">
              {{ $t('common.back') }}
            </a-button>
          </div>
        </template>
        <a-descriptions-item label="Статус">
          <status-view
            :status-list="PARTNER_STATUSES"
            :status="formState.status"
          ></status-view>
          <a-tag @click="modalVisible = true" color="#108ee9">
            <a-icon type="edit" />
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Дата создания">
          {{ formState.created | formatDate }}
        </a-descriptions-item>
        <a-descriptions-item label="Дата последнего обновления">
          {{ formState.updated | formatDate }}
        </a-descriptions-item>
        <a-descriptions-item label="Email">
          {{ formState.contact_info.email }}
        </a-descriptions-item>
        <a-descriptions-item label="Номер телефона">
          {{ formState.contact_info.phone }}
        </a-descriptions-item>
      </a-descriptions>
      <a-tabs v-show="!isFetching" :default-active-key="tabKey" type="card">
        <a-tab-pane
          key="0"
          tab="Товары партнера"
          v-can="{
            service: 'storefront',
            permissions: ['merchant-product-view'],
          }"
        >
          <partner-products-list></partner-products-list>
        </a-tab-pane>
        <a-tab-pane key="1" tab="Сервисные точки">
          <partner-pickup-point-list></partner-pickup-point-list>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Информация о партнере">
          <a-tabs tab-position="right">
            <a-tab-pane key="1" tab="Основная информация">
              <general-info :general-info="generalInfo"></general-info>
            </a-tab-pane>
            <a-tab-pane key="2" tab="О компании">
              <about-info :about-info="aboutInfo"></about-info>
            </a-tab-pane>
            <a-tab-pane key="3" tab="Банковские реквизиты">
              <bank-details :bank-details="bankDetails"></bank-details>
            </a-tab-pane>
            <a-tab-pane key="4" tab="Адрес">
              <address-info :address-info="addressInfo"></address-info>
            </a-tab-pane>
            <a-tab-pane key="5" tab="Комиссии">
              <comissions-info></comissions-info>
            </a-tab-pane>
            <a-tab-pane key="6" tab="Сотрудники">
              <employee-info></employee-info>
            </a-tab-pane>
            <a-tab-pane key="7" tab="График работы">
              <schedule-info :company-info-prop="scheduleInfo"></schedule-info>
            </a-tab-pane>
            <a-tab-pane key="8" tab="Документы">
              <documents-info></documents-info>
            </a-tab-pane>
          </a-tabs>
        </a-tab-pane>
        <a-tab-pane key="3" tab="Информация о заказах">
          <partner-orders-list></partner-orders-list>
        </a-tab-pane>
        <a-tab-pane key="4" tab="Действия">
          <a-tabs tab-position="right">
            <a-tab-pane key="0" tab="Загрузка Kaspi логов">
              <partner-kaspi-logs :merchant-id="merchantID" />
            </a-tab-pane>
          </a-tabs>
        </a-tab-pane>
      </a-tabs>
      <a-modal
        v-model="modalVisible"
        title="Сменить статус партнера"
        @ok="handleOk"
        cancel-text="Отмена"
        :ok-text="`Изменить на: ${
          PARTNER_STATUSES[newStatus] ? PARTNER_STATUSES[newStatus].label : ''
        }`"
        :ok-button-props="{
          props: { disabled: !newStatus },
        }"
      >
        <div>
          <h3>
            Текущий статус:
            <status-view
              :status-list="PARTNER_STATUSES"
              :status="formState.status"
            ></status-view>
          </h3>
          <div class="status-label">
            <p>Выберите новый статус партнера:</p>
            <template v-for="(item, index) in PARTNER_STATUSES">
              <a-tag
                v-if="item.value !== 'new' && formState.status !== item.value"
                :key="index"
                :color="item.variant"
                @click="newStatus = item.value"
              >
                <a-icon :type="item.icon" />
                {{ item.label }}
              </a-tag>
            </template>
          </div>
        </div>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState } from './constants'
import { filterOption } from 'Core/utils'

import PartnerProductsList from 'Partner/pages/Partner/PartnerInfo/PartnerProducts/PartnerProductsList'
import PartnerPickupPointList from 'Partner/pages/Partner/PartnerInfo/PartnerPickupPoint/PickupPointList'
import PartnerOrdersList from 'Partner/pages/Partner/PartnerInfo/PartnerOrders/PartnerOrders'
import PartnerKaspiLogs from 'Partner/pages/Partner/PartnerInfo/PartnerActions/PartnerKaspiLogs'

import GeneralInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/GeneralInfo'
import AboutInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/AboutInfo'
import BankDetails from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/BankDetails'
import AddressInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/AddressInfo'
import ComissionsInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/ComissionsInfo'
import EmployeeInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/EmployeeInfo'
import ScheduleInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/ScheduleInfo'
import DocumentsInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/DocumentsInfo'
import { PARTNER_STATUSES } from 'Core/types/partner'

export default {
  name: 'PartnerInfo',
  components: {
    PartnerProductsList,
    PartnerPickupPointList,
    PartnerOrdersList,
    GeneralInfo,
    AboutInfo,
    BankDetails,
    AddressInfo,
    ComissionsInfo,
    EmployeeInfo,
    ScheduleInfo,
    DocumentsInfo,
    PartnerKaspiLogs,
  },
  data: () => ({
    PARTNER_STATUSES,
    formState,
    generalInfo: {},
    aboutInfo: {},
    bankDetails: {},
    addressInfo: {},
    scheduleInfo: {},

    isFetching: true,
    modalVisible: false,
    newStatus: null,

    tabKey: '0',
  }),
  watch: {
    $route: function () {
      if (this.$route.query.tab) {
        this.tabKey = this.$route.query.tab
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.query.tab) {
        vm.tabKey = to.query.tab
      }
    })
  },
  methods: {
    filterOption,
    async handleOk() {
      try {
        await this.$API.MerchantProfile.updateProfile(this.merchantID, {
          status: this.newStatus,
        })
        await this.getPartnerByID()
        showSuccessMsg('Удачно обновлен!')
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.modalVisible = false
      }
    },
    async initialize() {
      try {
        this.isFetching = true
        await this.getPartnerByID()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getPartnerByID() {
      try {
        this.isFetching = true
        this.formState = await this.$API.MerchantProfile.getPartnerById(
          this.$route.params.id,
        )
        this.generalInfo = {
          logo_url: this.formState.logo_url,
          contact_info: this.formState.contact_info,
          store_name: this.formState.store_name,
        }
        this.aboutInfo = this.formState.company_info
        this.bankDetails = this.formState.company_info.payment_requisites
        this.scheduleInfo = this.formState.company_info
        this.formState.company_info.address.country = this.formState
          .company_info.address.country
          ? this.formState.company_info.address.country
          : 'Казахстан'
        this.addressInfo = this.formState.company_info.address
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    getImageParams(imgUrl) {
      this.formState.logo_url = imgUrl
    },
  },
  created() {
    this.initialize()
  },
  computed: {
    cityList() {
      return this.$store.getters['core/cities']
    },
    merchantID() {
      return this.$route.params.id
    },
  },
}
</script>
<style scoped lang="scss">
.merchant {
  &__link {
    margin-right: 20px;
  }
}

.status-box {
  display: flex;
  align-items: center;

  & span {
    margin: 0 10px;
  }
}

.status-label {
  & span {
    padding: 6px 16px;
    cursor: pointer;
    font-weight: bold;
  }
}
</style>
