<template>
  <div>
    <a-spin :spinning="isFetching">
      <div class="settings-wrap">
        <h2>
          Список быстрых ссылок:
          <b>
            <code>{{ fastLinkID }}</code>
          </b>
        </h2>
        <a-button @click="() => $router.push({ name: 'FastLinkList' })">
          {{ $t('common.back') }}
        </a-button>
      </div>
      <div class="settings-wrap">
        <a-button @click="isEditing = !isEditing">
          {{ isEditing ? 'Показать список ссылок' : 'Редактировать' }}
        </a-button>
        <a-button
          @click="
            () =>
              $router.push({
                name: 'LinkCreate',
                params: { fast_link_id: fastLinkID },
              })
          "
          >Создать ссылку
        </a-button>
      </div>
      <a-form-model
        v-if="isEditing"
        ref="form"
        :model="formState"
        :rules="rules"
        @submit="onValidate"
      >
        <a-form-model-item prop="id" label="ID">
          <a-input v-model="formState.id" />
        </a-form-model-item>

        <a-form-model-item prop="name" label="Название">
          <a-input v-model="formState.name" />
        </a-form-model-item>

        <a-form-model-item prop="title.ru" label="Заголовок(рус)">
          <a-input v-model="formState.title.ru" />
        </a-form-model-item>

        <a-form-model-item label="Заголовок(каз)">
          <a-input v-model="formState.title.kz" />
        </a-form-model-item>

        <a-form-model-item label="Заголовок(англ)">
          <a-input v-model="formState.title.en" />
        </a-form-model-item>
        <a-button type="primary" html-type="submit">Сохранить</a-button>
      </a-form-model>
      <a-table
        v-else
        :columns="columnNames"
        :data-source="formState.links"
        :scroll="{ x: true }"
        :locale="{ emptyText: 'Данные не найдены ):' }"
        :row-key="({ store_id }) => store_id"
        :loading="isLoading"
        bordered
      >
        <div slot="id" slot-scope="id">
          <router-link :to="`/fast_link/${fastLinkID}/link_id=${id}`">
            <code>{{ id | shortID(4) }}</code>
          </router-link>
        </div>
        <div slot="imageUrl" slot-scope="imageUrl">
          <img class="img-prev" :src="imageUrl" :alt="imageUrl" />
        </div>
        <div slot="isActive" slot-scope="isActive, record, index">
          <a-button
            v-if="isActive"
            type="primary"
            @click="statusToggleHandler(index)"
          >
            <a-icon type="check-circle" />
          </a-button>
          <a-button v-else type="danger" @click="statusToggleHandler(index)">
            <a-icon type="stop" />
          </a-button>
        </div>
        <div slot="devices" slot-scope="devices">
          <div class="icons-list">
            <a-icon v-if="checkDevices(devices, 'ios')" type="apple" />
            <a-icon v-if="checkDevices(devices, 'android')" type="android" />
            <a-icon v-if="checkDevices(devices, 'web')" type="chrome" />
          </div>
        </div>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState, rules, columnNames } from './constants'

export default {
  name: 'FastLinkInfo',
  data: () => ({
    formState,
    rules,
    columnNames,
    isEditing: false,
    isFetching: true,
    isLoading: true,
  }),
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        await this.getFastLinkById()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getFastLinkById() {
      this.formState = await this.$API.Menu.getFastLinkById(this.fastLinkID)
      this.isLoading = false
    },
    async statusToggleHandler(id) {
      this.formState.links[id].is_active = !this.formState.links[id].is_active
      try {
        await this.$API.Menu.updateLinkById(
          this.fastLinkID,
          this.formState.links[id].id,
          this.formState.links[id],
        )
        showSuccessMsg('Удачно обновлен!')
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    onValidate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.updateFastLinkById()
        showSuccessMsg('Удачно обновлен!')
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async updateFastLinkById() {
      // поля на бэк-е обязательные
      this.formState.title.kz = this.formState.title.kz
        ? this.formState.title.kz
        : this.formState.title.ru
      this.formState.title.en = this.formState.title.en
        ? this.formState.title.en
        : this.formState.title.ru
      await this.$API.Menu.updateFastLinkById(this.fastLinkID, this.formState)
    },
    checkDevices(list, item) {
      return list.includes(item)
    },
  },
  computed: {
    fastLinkID() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.img-prev {
  max-width: 170px;
  box-shadow: rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0 6px 6px;
  border-radius: 4px;
}

.icons-list {
  margin-right: 6px;
  font-size: 24px;
}
</style>
