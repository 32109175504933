import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис приёма/парсинга фида от мерчантов
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/merchant-gateway/swagger/index.html} */
export class MerchantGatewayService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'merchant-gateway',
    })
  }

  @Api
  async getUnrecognizedMerchantProductsList(params) {
    const response = await this.GET('/v2/merchants/products/list', params)
    return response
  }

  @Api
  async bindMerchantProduct(params) {
    const response = await this.PUT(`/v2/merchants/products/connection`, params)
    return response
  }

  @Api
  async unbindMerchantProduct(merchant_sku, params) {
    const url = `/v1/merchant/documents/products/${merchant_sku}/unconnection`
    const response = await this.PUT(url, {}, {}, params)
    return response
  }

  @Api
  async rebindMerchantProduct(params) {
    const response = await this.PUT(
      '/v1/merchant/documents/products/rebind',
      params,
      {},
      {},
    )
    return response
  }
}
