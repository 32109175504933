import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис управления промокодами
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/promocode/swagger/index.html} */
export class PromoCodeService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'promocode',
    })
  }

  @Api
  async getPromoCodeList(params) {
    const response = await this.GET(`/v1/specifications`, params)
    return response
  }

  @Api
  async getPromoCodeById(id) {
    const response = await this.GET(`/v1/specifications/${id}`)
    return response
  }

  @Api
  async createPromoCode(payload) {
    const response = await this.POST(`/v1/specifications`, payload)
    return response
  }

  @Api
  async updatePromoCodeById(id, payload) {
    const response = await this.PUT(`/v1/specifications1/${id}`, payload)
    return response
  }

  @Api
  async deletePromoCode(id) {
    const response = await this.DELETE(`/v1/specifications/${id}`)
    return response
  }
}
