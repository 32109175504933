<template>
  <div>
    <div class="settings-wrap">
      <h2>Информация о заказе #{{ orderInfo.id }}</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'OrdersList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-spin :spinning="OrderInfoIsLoading">
      <a-descriptions
        :column="7"
        class="description-padding"
        title="Информация о заказе"
        bordered
        layout="vertical"
      >
        <a-descriptions-item label="Номер заказа">
          {{ orderInfo.id }}
        </a-descriptions-item>
        <a-descriptions-item label="Заказы продавцов">
          <ul>
            <li v-for="(item, index) in orderInfo.sub_orders" :key="index">
              <router-link
                :to="{
                  name: 'PartnerOrderInfo',
                  params: { id: item.merchant_id, orderId: item.id },
                }"
              >
                {{ item.merchant_title }} - {{ item.id }}
              </router-link>
            </li>
          </ul>
        </a-descriptions-item>
        <a-descriptions-item label="Тип заказа">
          <template v-if="orderInfo.dividable"> Делимый заказ </template>
          <template v-else> Неделимый заказ </template>
        </a-descriptions-item>
        <a-descriptions-item label="Статус заказа">
          <status-view
            :status-list="ORDER_STATUSES"
            :status="orderInfo.status"
          ></status-view>
        </a-descriptions-item>
        <a-descriptions-item label="Способ оплаты">
          <template v-if="PaymentTypes[orderInfo.payment_type]">
            <a-icon :type="PaymentTypes[orderInfo.payment_type].icon"></a-icon>
            {{ PaymentTypes[orderInfo.payment_type].label }}
          </template>
          <template v-else>
            Неизвестный способ оплаты [{{ orderInfo.payment_type }}]
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Статус оплаты">
          <status-view
            :status-list="PAYMENT_STATUSES"
            :status="orderInfo.payment_status"
          ></status-view>
        </a-descriptions-item>
        <a-descriptions-item label="Сумма заказа">
          {{ orderInfo.total_price | currency }}
        </a-descriptions-item>
      </a-descriptions>
      <a-collapse class="description-padding">
        <a-collapse-panel key="1" header="Информация о клиенте">
          <a-descriptions bordered layout="vertical">
            <a-descriptions-item label="Фио клиента">
              {{ orderInfo.customer.full_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Email">
              {{ orderInfo.customer.email }}
            </a-descriptions-item>
            <a-descriptions-item label="Контактные номера">
              {{ orderInfo.customer.phone }}
            </a-descriptions-item>
          </a-descriptions>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="Информация о доставке">
          <a-descriptions bordered layout="vertical">
            <a-descriptions-item label="ФИО получателя" span="2">
              {{ orderInfo.recipient.full_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Телефон получателя">
              {{ orderInfo.recipient.phone }}
            </a-descriptions-item>
            <a-descriptions-item label="Адрес" span="3">
              <p>{{ orderInfo.address }}</p>
            </a-descriptions-item>
          </a-descriptions>
        </a-collapse-panel>
      </a-collapse>

      <a-table
        v-if="isDivisible"
        class="description-padding"
        :columns="columnNames"
        :data-source="orderInfo.products"
        :scroll="{ x: true }"
        :locale="{ emptyText: 'Данные не найдены ):' }"
        :row-key="({ id }) => id"
        :pagination="false"
        :row-selection="rowSelection"
        bordered
      >
        <div slot="sku" slot-scope="sku">
          <router-link :to="`/base-product/${sku}`" target="_blank">
            {{ sku }}
          </router-link>
        </div>
        <div slot="storage_type" slot-scope="storage_type">
          <template v-if="storage_type === 'fbo'">
            <p>На складе AIRBA</p>
          </template>
          <template v-else-if="storage_type === 'fbs'">
            <p>На складе у партнера</p>
          </template>
          <template v-else>
            <p>
              Не известный тип хранения <strong>{{ storage_type }}</strong>
            </p>
          </template>
        </div>
        <div slot="title" slot-scope="title, sku">
          <template v-if="sku && sku.sku">
            <a :href="`${showcaseUrl}product/${sku.sku}`" target="_blank">
              {{ title.ru }}
              <a-icon type="link" />
            </a>
          </template>
        </div>
        <div slot="merchant_title" slot-scope="merchant_title">
          <template v-if="merchant_title.ru">
            {{ merchant_title.ru }} [ RU ]
          </template>
          <template v-else-if="merchant_title.kz">
            {{ merchant_title.kz }} [ KZ ]
          </template>
          <template v-else-if="merchant_title.en">
            {{ merchant_title.en }} [ EN ]
          </template>
          <template v-else>
            Название продавца отсутсвует
            <a-icon style="color: #ff4d4f" type="warning" />
          </template>
        </div>
        <div slot="pickup_point_id" slot-scope="pickup_point_id, item">
          <router-link
            :to="`/pickup-point/merchant=${item.merchant_id}/id=${pickup_point_id}`"
            target="_blank"
          >
            {{ pickup_point_id }}
          </router-link>
        </div>
        <div slot="price" slot-scope="price">
          {{ price | currency }}
        </div>
        <div slot="total_price" slot-scope="total_price">
          {{ total_price | currency }}
        </div>
        <div slot="status" slot-scope="status">
          <status-view
            :status-list="PRODUCT_STATUSES"
            :status="status"
          ></status-view>
        </div>
      </a-table>
      <a-table
        v-else
        class="description-padding"
        :columns="columnNames"
        :data-source="orderInfo.products"
        :scroll="{ x: true }"
        :locale="{ emptyText: 'Данные не найдены ):' }"
        :row-key="({ id }) => id"
        :pagination="false"
        bordered
      >
        <div slot="sku" slot-scope="sku">
          <router-link :to="`/base-product/${sku}`">
            <code>{{ sku }}</code>
          </router-link>
        </div>
        <div slot="title" slot-scope="title">
          {{ title.ru }}
        </div>
        <div slot="title" slot-scope="merchant">
          {{ merchant.ru }}
        </div>
        <div slot="price" slot-scope="price">
          {{ price | currency }}
        </div>
        <div slot="total_price" slot-scope="total_price">
          {{ total_price | currency }}
        </div>
        <div slot="status" slot-scope="status">
          <status-view
            :status-list="PRODUCT_STATUSES"
            :status="status"
          ></status-view>
        </div>
      </a-table>
      <a-button type="danger" @click="returnHandler">
        Оформить заявку на возврат
      </a-button>
      <a-modal
        v-model="returnModal"
        title="Оформление заявки на возврат"
        cancel-text="Отмена"
        ok-text="Оформить возврат"
        @ok="handleOnReturn"
      >
        <div class="return-modal">
          <a-form-model
            ref="form"
            :model="returnForm"
            :rules="returnFormRules"
            @submit="handleOnReturn"
          >
            <a-form-model-item label="Причина">
              <a-radio-group v-model="returnForm.reason">
                <a-radio-button value="changed_mind">
                  Передумал покупать
                </a-radio-button>
                <a-radio-button value="defective"> Брак</a-radio-button>
                <a-radio-button value="other"> Другое</a-radio-button>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item prop="name" label="Коментарий">
              <a-textarea
                allow-clear
                v-model="returnForm.comment"
                :auto-size="{ minRows: 2, maxRows: 4 }"
              />
            </a-form-model-item>
            <a-form-model-item prop="name" label="Товары">
              <div>
                <a-table
                  class="description-padding"
                  :columns="returnColumnNames"
                  :data-source="returnForm.items"
                  :scroll="{ x: true }"
                  :locale="{ emptyText: 'Данные не найдены ):' }"
                  :row-key="({ sku }) => sku"
                  :pagination="false"
                  bordered
                >
                  <div slot="quantity" slot-scope="quantity, record">
                    <a-input-number
                      :disabled="!isDivisible"
                      v-model="record.quantity"
                      :min="1"
                      :max="record.max_amount"
                    />
                  </div>
                </a-table>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

import {
  orderInfo,
  columnNames,
  returnForm,
  returnFormRules,
  returnColumnNames,
} from 'Order/pages/OrderInfo/constants'
import { PaymentTypes } from 'Core/constants'
import {
  ORDER_STATUSES,
  PRODUCT_STATUSES,
  PAYMENT_STATUSES,
} from 'Core/types/order'

export default {
  data() {
    return {
      ORDER_STATUSES,
      PAYMENT_STATUSES,
      PRODUCT_STATUSES,
      PaymentTypes,
      columnNames,
      orderInfo,
      returnForm,
      returnFormRules,
      returnColumnNames,
      OrderInfoIsLoading: false,
      returnModal: false,
      selectedRows: [],
      showcaseUrl: process.env.VUE_APP_SHOWCASE_URL,
    }
  },
  computed: {
    orderId() {
      return this.$route.params.id
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.returnForm.items = selectedRows
        },
      }
    },
    isDivisible() {
      return (
        this.orderInfo.payment_type === 'card_online' ||
        this.orderInfo.payment_type === 'card_offline'
      )
    },
  },
  methods: {
    async initialize() {
      try {
        this.OrderInfoIsLoading = true
        this.orderInfo = await this.$API.OMS.getOrderItemInfo(this.orderId)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.OrderInfoIsLoading = false
      }
    },
    returnHandler() {
      this.returnModal = true
      this.returnForm.order_id = this.orderId
      if (!this.isDivisible || this.returnForm.items.length === 0) {
        this.returnForm.items = this.orderInfo.products
      }
      this.returnForm.items = this.returnForm.items.map((item) => {
        return {
          ...item,
          max_amount: item.quantity,
        }
      })
    },
    async handleOnReturn() {
      this.returnModal = false
      await this.$API.RMS.purchaseReturn(this.returnForm)
      showSuccessMsg('Возврат оформлен.')
      await this.initialize()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
::v-deep .ant-collapse-content-box {
  padding: 0 !important;
}

.description-padding {
  margin-top: 32px;
}

.return-modal {
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
