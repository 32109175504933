<template>
  <a-modal
    title="Привязать товар партнера"
    :visible="isVisible"
    :confirm-loading="isLoading"
    @cancel="onClose"
    :footer="null"
    :destroy-on-close="true"
    width="55vw"
  >
    <a-form-model
      @submit="onSubmit"
      ref="form"
      :rules="bindProductFormRules"
      :model="params"
    >
      <a-form-model-item prop="baseProduct" label="Базовый продукт">
        <a-auto-complete
          v-model="params.search"
          style="width: 100%"
          placeholder="Введите названиe или бренд"
          @change="onChange"
          :allow-clear="true"
          :auto-focus="true"
        >
          <template v-slot:dataSource>
            <a-select-option
              v-for="resultItem in searchResult"
              :key="resultItem.sku"
              @click="onSelect(resultItem)"
            >
              {{ resultItem.title.ru }} (CКУ: {{ resultItem.sku }})
            </a-select-option>
          </template>
        </a-auto-complete>
      </a-form-model-item>

      <a-descriptions
        title="Информация по базовому продукту"
        v-if="selectedOption"
        :column="{ md: 3, lg: 6 }"
      >
        <a-descriptions-item label="Название">
          {{ selectedOption.title.ru || 'Не указан' }}
        </a-descriptions-item>
        <a-descriptions-item label="Брэнд">
          {{ selectedOption.brand.title.ru || 'Не указан' }}
        </a-descriptions-item>
        <a-descriptions-item label="Условия" v-if="selectedOption.installment">
          {{ selectedOption.installment.title.ru || 'Не указан' }}
        </a-descriptions-item>
        <a-descriptions-item label="Цвет">
          <div
            class="color"
            v-if="selectedOption.color.hex || selectedOption.color.title.ru"
          >
            <span
              :style="{ background: selectedOption.color.hex }"
              class="color__label"
              v-if="selectedOption.color.hex"
            ></span>
            <span v-if="selectedOption.color.title.ru">
              {{ selectedOption.color.title.ru }}
            </span>
          </div>
          <span v-else>Не указан</span>
        </a-descriptions-item>
        <a-descriptions-item label="Цена">
          {{ selectedOption.price.current | currency }}
        </a-descriptions-item>
        <a-descriptions-item label="Рейтинг">
          <a-rate
            disabled
            :default-value="selectedOption.rating"
            v-if="selectedOption.rating"
          />
          <span v-else>Не указан</span>
        </a-descriptions-item>
      </a-descriptions>
      <a-button
        type="primary"
        html-type="submit"
        v-if="actionType == 'bindToProduct'"
        >Привязать
      </a-button>
      <a-button
        type="primary"
        @click="emitAddProduct"
        v-if="actionType == 'bindToGroup'"
        >Выбрать
      </a-button>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  bindMerchantProductAPI,
  unbindMerchantProductAPI,
} from 'Partner/api/merchantProducts'
import { showErrorMsg, showSuccessMsg, showWarningMsg } from 'Core/utils'
import { bindProductFormRules } from 'Partner/components/constants'

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    merchantSku: {
      type: String,
    },
    requestParams: {
      type: Object,
    },
    actionType: {
      type: String,
    },
  },
  data() {
    return {
      bindProductFormRules,
      searchResult: [],
      params: {},
      isLoading: false,
      selectedOption: null,
    }
  },
  watch: {
    requestParams: {
      handler(newValue) {
        this.params = newValue
      },
      immediate: true,
    },
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  methods: {
    onSelect(option) {
      this.selectedOption = option
    },
    emitAddProduct() {
      if (this.selectedOption) {
        this.$emit('addProduct', this.selectedOption)
        this.onClose()
      } else {
        showWarningMsg('Выберите продукт из списка результатов')
      }
    },
    async onChange() {
      try {
        if (this.params.search) {
          const { products } =
            this.actionType == 'bindToGroup'
              ? await this.$API.Storefront.getBaseProductList(this.params)
              : await this.$API.Storefront.searchUnconnectedBaseProduct(
                  this.merchantID,
                  this.params,
                )
          this.searchResult = products
        } else {
          this.params.search = this.selectedOption = null
        }
      } catch (error) {
        showErrorMsg(error?.message)
      } finally {
        this.isLoading = false
      }
    },
    async onSubmit(event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.selectedOption) {
        try {
          this.isLoading = true
          const params = {
            merchant_id: this.merchantID,
          }
          const payload = {
            sku: this.selectedOption.sku,
          }
          await unbindMerchantProductAPI(this.merchantSku, params)
          await bindMerchantProductAPI(this.merchantSku, payload, params)
          this.onClose()
          this.$emit('closeModal', false)
          showSuccessMsg('Товар успешно привязан!')
        } catch (error) {
          showErrorMsg(error?.message)
        } finally {
          this.isLoading = false
        }
      } else {
        showWarningMsg(
          'Пожалуйста, выберите товар из списка результатов поиска',
        )
      }
    },
    onClose() {
      this.$emit('closeModal', false)
      this.$refs.form.resetFields()
      this.selectedOption = this.searchResult = this.params.search = null
    },
  },
}
</script>

<style lang="scss" scoped>
.color {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__label {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

::v-deep {
  .ant-modal {
    top: 25%;
  }

  .ant-descriptions-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-descriptions-row {
    gap: 30px;
    display: flex;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .ant-descriptions-item-label {
    font-weight: bold;
  }
}
</style>
