import { PHONE } from 'Core/modules/validators/phone'
import { ALPHA } from 'Core/modules/validators/alpha'
import { EMAIL } from 'Core/modules/validators/email'
import { INTEGER } from 'Core/modules/validators/numbers'

export const createPartnerRules = {
  phone: [
    {
      required: true,
      fullField: 'Номер телефона',
    },
    {
      fullField: 'Номер телефона',
      validator: PHONE,
    },
  ],
  logo_url: {
    required: true,
    fullField: 'Логотип',
    size: 2,
    formats: ['image/jpeg', 'image/png', 'image/jpg'],
  },
  store_name: [
    {
      required: true,
      fullField: 'Название компании',
      type: 'string',
      max: 128,
    },
    {
      validator: ALPHA,
      fullField: 'Название компании',
      langs: ['en', 'ru'],
      numeric: true,
      specials: [
        'space',
        '.',
        ',',
        "'",
        '"',
        ':',
        '»',
        '«',
        '&',
        '-',
        '(',
        ')',
        '_',
        '/',
      ],
    },
  ],
  category: [
    {
      fullField: 'Категория',
      required: true,
      max: 128,
    },
    {
      fullField: 'Категория',
      validator: ALPHA,
      langs: ['ru'],
      specials: ['space'],
    },
  ],
  first_name: [
    {
      required: true,
      fullField: 'Имя',
      max: 50,
    },
    {
      validator: ALPHA,
      fullField: 'Имя',
      langs: ['ru', 'kz', 'en'],
      specials: ['space', '-'],
    },
  ],
  last_name: [
    {
      required: true,
      fullField: 'Фамилия',
      max: 50,
    },
    {
      validator: ALPHA,
      fullField: 'Фамилия',
      langs: ['ru', 'kz', 'en'],
      specials: ['space', '-'],
    },
  ],
  patronymic: [
    {
      required: false,
      max: 50,
    },
    {
      validator: ALPHA,
      fullField: 'Отчество',
      langs: ['ru', 'kz', 'en'],
      specials: ['space', '-'],
    },
  ],
  email: [
    {
      required: true,
      fullField: 'Email',
      max: 50,
    },
    {
      fullField: 'Email',
      validator: EMAIL,
    },
  ],
  ownership: {
    type: 'enum',
    enum: ['ТОО', 'ИП', 'АО'],
    fullField: 'Тип компании',
    required: true,
  },
  company_name: [
    {
      required: true,
      fullField: 'Юридическое название компании',
      max: 128,
    },
    {
      fullField: 'Юридическое название компании',
      validator: ALPHA,
      langs: ['en', 'ru'],
      numeric: true,
      specials: [
        'space',
        '.',
        ',',
        "'",
        '"',
        ':',
        '»',
        '«',
        '&',
        '-',
        '(',
        ')',
        '_',
        '/',
      ],
    },
  ],
  iin: [
    {
      fullField: 'БИН',
      required: true,
      type: 'string',
      len: 12,
    },
    {
      fullField: 'БИН',
      validator: INTEGER,
    },
  ],
}
