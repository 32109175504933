import { PUT } from '@/modules/Main/api/REST'

const URLS = {
  recognizedProductsUrl: `${process.env.VUE_APP_API_URL}/storefront/api/v1/merchants`,
  unrecognizedProductsUrl: `${process.env.VUE_APP_API_URL}/merchant-gateway/api/v1/merchant/documents/products`,
}

/**
 * refactor(api-gateway): ветка tech/api-gateway отстает от ветки develop. В компоненте BindProductModal в ветке develop
 * вместо этих двух методов используется метод rebind
 *
 * PS: Рома сказал, что сам поправит. После этого этот файл нужно удалить.
 */

export const bindMerchantProductAPI = async (merchant_sku, payload, params) => {
  const bindMerchantProductUrl = `${URLS.unrecognizedProductsUrl}/${merchant_sku}/connection`

  const { data, success } = await PUT(
    bindMerchantProductUrl,
    payload,
    {},
    params,
  )

  if (!success) {
    // TODO: REFACTORING THIS AFTER DEMO
    switch (data?.error?.message) {
      case 'product price is lower than minimum': {
        throw new Error(
          `Привязка товара не удалась. Цена продукта ниже цены базового продукта`,
        )
      }
      case 'product is connected': {
        throw new Error(
          `Привязка товара не удалась. Продукт партнера уже привязан к базовому продукту`,
        )
      }
      case 'base product not exists': {
        throw new Error(`Привязка товара не удалась. Не найден базовый продукт`)
      }
      default: {
        throw new Error(`Привязка товара не удалась`)
      }
    }
  }
  return data
}

export const unbindMerchantProductAPI = async (merchant_sku, params) => {
  const bindMerchantProductUrl = `${URLS.unrecognizedProductsUrl}/${merchant_sku}/unconnection`

  const { data, success } = await PUT(bindMerchantProductUrl, {}, {}, params)

  if (!success) {
    throw new Error(
      `Отвязка товара не удалась. Сообщение ошибки: "${data.error.message}"`,
    )
  }
  return data
}
