export const PRODUCT_STATUSES = {
  waiting_confirmation: {
    label: 'Ожидает подтверждения',
    value: 'waiting_confirmation',
    variant: 'orange',
  },
  on_assembly: {
    label: 'На сборке',
    value: 'on_assembly',
    variant: 'orange',
  },
  assembled: {
    label: 'Собран',
    value: 'assembled',
    variant: 'orange',
  },
  reassembly: {
    label: 'Пере-сборка',
    value: 'reassembly',
    variant: 'orange',
  },
  courier_appointed: {
    label: 'Курьер назначен',
    value: 'courier_appointed',
    variant: 'orange',
  },
  courier_goes_to_pickup: {
    label: 'Курьер направляется забрать продукт',
    value: 'courier_goes_to_pickup',
    variant: 'orange',
  },
  courier_arrived_for: {
    label: 'Курьер прибыл забрать продукт',
    value: 'courier_arrived_for',
    variant: 'orange',
  },
  courier_checked_and_took: {
    label: 'Курьер проверил и забрал продукт',
    value: 'courier_checked_and_took',
    variant: 'orange',
  },
  courier_went_to_deliver: {
    label: 'Курьер отправился на доставку',
    value: 'courier_went_to_deliver',
    variant: 'orange',
  },
  courier_arrived_at_buyer: {
    label: 'Курьер прибыл к покупателю',
    value: 'courier_arrived_at_buyer',
    variant: 'orange',
  },
  delivered: {
    label: ' Продукт доставлен',
    value: 'delivered',
    variant: 'green',
  },
  returned: {
    label: 'Продукт возвращен покупателем',
    value: 'returned',
    variant: 'blue',
  },
  canceled: {
    value: 'canceled',
    variant: 'red',
    label: 'Отменен',
  },
  canceled_by_merchant: {
    label: 'Отменен по решению продавца',
    value: 'canceled_by_merchant',
    variant: 'red',
  },
  canceled_by_buyer: {
    label: 'Отменен по решению покупателя',
    value: 'canceled_by_buyer',
    variant: 'red',
  },
  canceled_by_cls: {
    label: 'Отменен по решению доставки',
    value: 'canceled_by_cls',
    variant: 'red',
  },
  merchant_did_not_confirm: {
    label: 'Продавец не подтвердил продукт вовремя',
    value: 'merchant_did_not_confirm',
    variant: 'red',
  },
  merchant_did_not_assembly: {
    label: 'Продавец не собрал продукт вовремя',
    value: 'merchant_did_not_assembly',
    variant: 'red',
  },
}
export const ORDER_STATUSES = {
  waiting_confirmation: {
    label: 'Ожидает подтверждения',
    value: 'waiting_confirmation',
    variant: 'orange',
  },
  not_paid: { value: 'not_paid', variant: 'orange', label: 'Не оплачен' },
  in_process: { value: 'in_process', variant: 'orange', label: 'В процессе' },
  on_assembly: {
    label: 'На сборке',
    value: 'on_assembly',
    variant: 'orange',
  },
  assembled: {
    label: 'Собран',
    value: 'assembled',
    variant: 'orange',
  },
  reassembly: {
    label: 'Пере-сборка',
    value: 'reassembly',
    variant: 'orange',
  },
  delivering: {
    label: 'Доставка',
    value: 'delivering',
    variant: 'orange',
  },
  payment_failure: {
    value: 'payment_failure',
    variant: 'red',
    label: 'Ошибка оплаты',
  },
  payment_failure_by_deadline: {
    value: 'payment_failure_by_deadline',
    variant: 'red',
    label: 'Заказ не был оплачен вовремя',
  },
  completed: { value: 'completed', variant: 'green', label: 'Завершен' },
  returned: { value: 'returned', variant: 'red', label: 'Возвращен' },
  canceled_by_merchant: {
    value: 'canceled_by_merchant',
    variant: 'red',
    label: 'Отменен по решению продавца',
  },
  canceled_by_buyer: {
    value: 'canceled_by_buyer',
    variant: 'red',
    label: 'Отменен по решению покупателя',
  },
  canceled_by_cls: {
    value: 'canceled_by_cls',
    variant: 'red',
    label: 'Отменен по решению доставки',
  },
  canceled: {
    value: 'canceled',
    variant: 'red',
    label: 'Отменен',
  },
}

export const PAYMENT_STATUSES = {
  not_paid: { value: 'not_paid', variant: 'orange', label: 'Не оплачен' },
  payment_upon_receipt: {
    value: 'payment_upon_receipt',
    variant: 'orange',
    label: 'Оплата при получении',
  },
  paid: { value: 'paid', variant: 'green', label: 'Оплачен' },
  payment_failure_by_deadline: {
    value: 'payment_failure_by_deadline',
    variant: 'red',
    label: 'Не оплачен вовремя',
  },
}
