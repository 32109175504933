import RoleList from 'Config/pages/Role/RoleList/RoleList'
import RoleEdit from 'Config/pages/Role/RoleEdit/RoleEdit'

import ComissionView from 'Config/pages/Comission/ComissionView/ComissionView'
import ComissionEdit from 'Config/pages/Comission/ComissionEdit/ComissionEdit'

export default [
  {
    path: '/roles',
    name: 'RoleList',
    component: RoleList,
    meta: {
      permission: {
        service: 'role',
        permissions: ['role-assign'],
      },
      search: {
        name: 'Роли',
        icon: 'key',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/role/:id',
    name: 'RoleEdit',
    component: RoleEdit,
    meta: {
      permission: {
        service: 'role',
        permissions: ['role-assign'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/commission',
    name: 'ComissionView',
    component: ComissionView,
    meta: {
      permission: {
        service: 'role',
        permissions: ['role-assign'],
      },
      search: {
        name: 'Комиссии',
        icon: 'dollar',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/commission-edit',
    name: 'ComissionEdit',
    component: ComissionEdit,
    meta: {
      permission: {
        service: 'role',
        permissions: ['role-assign'],
      },
      failPath: '/censor',
    },
  },
]
