import { mapStateToFSM } from 'Core/modules/helpers'

export default {
  namespaced: true,
  state: {
    contacts: null,
    isLoading: false,
  },
  mutations: {
    SET_CONTACTS(state, platforms) {
      state.contacts = platforms
    },
    SET_CONTACTS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  actions: {
    async fetchContacts(context) {
      let data = null
      try {
        context.commit('SET_CONTACTS_LOADING', true)
        data = await this._vm.$API.ConfigDiscovery.getContactList()
      } finally {
        context.commit('SET_CONTACTS_LOADING', false)
        context.commit('SET_CONTACTS', data)
      }
    },
  },
  getters: {
    /** платформы в ФСМ формате. Будут использованы позже */
    contactsFSM: (state) =>
      mapStateToFSM(state.contacts, {
        label: 'name',
        value: 'id',
        variant: 'type',
        icon: 'icon_url',
      }),
    contacts: (state) => state.contacts,
    isLoading: (state) => state.isLoading,
  },
}
