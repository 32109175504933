import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис управления заказами
 *   <br> Swagger
 *   <br> {@link https://api.mp-test.airba.dev/oms/swagger/buyer/index.html}
 *   <br> {@link https://api.mp-test.airba.dev/oms/swagger/merchant/index.html}
 *   <br> {@link https://api.mp-test.airba.dev/oms/swagger/thirdparty/index.html} */
export class OMSService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'oms',
    })
  }

  @Api
  async getMerchantOrderList(params) {
    const response = await this.GET(`/v1/admin/orders/merchant-view`, params)
    return response
  }

  @Api
  async getMerchantOrderItemInfo(id) {
    const response = await this.GET(`/v1/admin/orders/merchant-view/${id}`)
    return response
  }

  @Api
  async setMerchantOrderStatus(mid, id, solution) {
    const response = await this.POST(`/v1/admin/orders/${mid}/${id}`, solution)
    return response
  }

  @Api
  async getOrderList(params) {
    const response = await this.GET(`/v1/admin/orders`, params)
    return response
  }

  @Api
  async getOrderItemInfo(id) {
    const response = await this.GET(`/v1/admin/orders/${id}`)
    return response
  }
}
