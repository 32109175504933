import { EMAIL } from 'Core/modules/validators/email'
import { PHONE } from 'Core/modules/validators/phone'
import { ALPHA } from 'Core/modules/validators/alpha'
import { FILE_SAVED } from 'Core/modules/validators/file'
import { IBAN } from 'Core/modules/validators/requisites'
import { INTEGER } from 'Core/modules/validators/numbers'

export const formState = {
  created: null,
  delivery_date: null,
  enabled: true,
  id: null,
  is_accepted: true,
  loan_term: null,
  logo_url: null,
  status: '',
  store_name: null,
  updated: null,
  site_url: null,
  contact_info: {
    first_name: null,
    last_name: null,
    patronymic: null,
    phone: null,
    email: null,
  },
  company_info: {
    ownership: 'LLP',
    company_name: null,
    first_name_ceo: null,
    last_name_ceo: null,
    patronymic_ceo: null,
    email: '',
    address: {
      country: 'Казахстан', // после нужно id
      city: null, // после нужно id
    },
    payment_requisites: {
      account_bank_number: null,
      bik: null,
      bin: null,
      kbe: null,
    },
  },
}

export const profileStateRules = {
  store_name: [
    {
      required: true,
      fullField: 'Название компании',
      type: 'string',
      max: 128,
    },
    {
      validator: ALPHA,
      fullField: 'Название компании',
      langs: ['en', 'ru'],
      numeric: true,
      specials: [
        'space',
        '.',
        ',',
        "'",
        '"',
        ':',
        '»',
        '«',
        '&',
        '-',
        '(',
        ')',
        '_',
        '/',
      ],
    },
  ],
  fileList: [
    {
      validator: FILE_SAVED,
      fullField: 'Иконка',
    },
  ],
  contact_info: {
    first_name: [
      {
        required: true,
        fullField: 'Имя',
        max: 50,
      },
      {
        validator: ALPHA,
        fullField: 'Имя',
        langs: ['ru', 'kz', 'en'],
        specials: ['space', '-'],
      },
    ],
    last_name: [
      {
        required: true,
        fullField: 'Фамилия',
        max: 50,
      },
      {
        validator: ALPHA,
        fullField: 'Фамилия',
        langs: ['ru', 'kz', 'en'],
        specials: ['space', '-'],
      },
    ],
    patronymic: [
      {
        required: false,
        max: 50,
      },
      {
        validator: ALPHA,
        fullField: 'Отчество',
        langs: ['ru', 'kz', 'en'],
        specials: ['space', '-'],
      },
    ],
    email: [
      {
        required: true,
        fullField: 'Email',
        max: 50,
      },
      {
        fullField: 'Email',
        validator: EMAIL,
      },
    ],
    phone: [
      {
        required: true,
        fullField: 'Номер телефона',
      },
      {
        fullField: 'Номер телефона',
        validator: PHONE,
      },
    ],
  },
}

export const companyInfoStateRules = {
  ownership: {
    type: 'enum',
    enum: ['ТОО', 'ИП', 'АО'],
    fullField: 'Тип компании',
    required: true,
  },
  company_name: [
    {
      required: true,
      fullField: 'Юридическое название компании',
      max: 128,
    },
    {
      fullField: 'Юридическое название компании',
      validator: ALPHA,
      langs: ['en', 'ru'],
      numeric: true,
      specials: [
        'space',
        '.',
        ',',
        "'",
        '"',
        ':',
        '»',
        '«',
        '&',
        '-',
        '(',
        ')',
        '_',
        '/',
      ],
    },
  ],
  email: [
    {
      fullField: 'Email',
      required: true,
      max: 50,
    },
    {
      fullField: 'Email',
      validator: EMAIL,
    },
  ],
  first_name_ceo: [
    {
      fullField: 'Имя',
      required: true,
      max: 50,
    },
    {
      fullField: 'Имя',
      validator: ALPHA,
      langs: ['ru', 'kz', 'en'],
      specials: ['space', '-'],
    },
  ],
  last_name_ceo: [
    {
      fullField: 'Фамилия',
      required: true,
      max: 50,
    },
    {
      fullField: 'Фамилия',
      validator: ALPHA,
      langs: ['ru', 'kz', 'en'],
      specials: ['space', '-'],
    },
  ],
  patronymic_ceo: [
    {
      fullField: 'Отчество',
      required: false,
      max: 50,
    },
    {
      fullField: 'Отчество',
      validator: ALPHA,
      langs: ['ru', 'kz', 'en'],
      specials: ['space', '-'],
    },
  ],
  phone_number: [
    {
      fullField: 'Номер телефона',
      required: true,
    },
    {
      fullField: 'Номер телефона',
      validator: PHONE,
    },
  ],
}

export const bankReqStateRules = {
  account_bank_number: [
    {
      fullField: 'Номер счета в банке',
      required: true,
    },
    {
      fullField: 'Номер счета в банке',
      validator: IBAN,
    },
  ],
  bin: [
    {
      fullField: 'БИН',
      required: true,
      type: 'string',
      len: 12,
    },
    {
      fullField: 'БИН',
      validator: INTEGER,
    },
  ],
  bik: [
    {
      fullField: 'БИК',
      type: 'string',
      required: true,
      len: 8,
    },
    {
      fullField: 'БИК',
      validator: ALPHA,
      langs: ['en'],
    },
  ],
  kbe: [
    {
      fullField: 'КБЕ',
      len: 2,
      required: true,
    },
    {
      fullField: 'КБЕ',
      validator: INTEGER,
    },
  ],
}
export const addressInfoStateRules = {
  country: {
    type: 'string',
    required: true,
    fullField: 'Страна',
  },
  city: {
    required: true,
    fullField: 'Город',
  },
  index: [
    {
      required: true,
      fullField: 'Индекс',
    },
    {
      validator: ALPHA,
      fullField: 'Индекс',
      langs: ['en'],
      numeric: true,
    },
  ],
  street: [
    {
      fullField: 'Улица',
      required: false,
      max: 50,
    },
    {
      validator: ALPHA,
      fullField: 'Улица',
      langs: ['en', 'ru'],
      numeric: true,
      specials: ['space', '.', ',', "'", '"', ':', '»', '«', '/', '\\'],
    },
  ],
}
export const comissionsColumnNames = [
  {
    title: 'Категория',
    dataIndex: 'category_title.ru',
    key: 'category',
    scopedSlots: { customRender: 'title' },
    align: 'right',
  },
  {
    title: 'Комиссия',
    dataIndex: 'value',
    key: 'value',
    scopedSlots: { customRender: 'rate' },
    align: 'right',
    width: '140px',
  },
]
export const employeesColumns = [
  {
    title: 'ID',
    dataIndex: 'tdid',
    key: 'tdid',
    scopedSlots: { customRender: 'tdid' },
  },
  {
    title: 'ФИО',
    dataIndex: '',
    key: 'fio',
    scopedSlots: { customRender: 'fio' },
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone_number',
    key: 'phone_number',
    scopedSlots: { customRender: 'phone_number' },
  },
  {
    title: 'Роли',
    dataIndex: 'roles',
    key: 'roles',
    scopedSlots: { customRender: 'roles' },
  },
  {
    title: 'Действия',
    dataIndex: '',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export const employeeSearchFormRules = {
  phone: [
    {
      type: 'string',
      required: true,
    },
    {
      validator: PHONE,
    },
  ],
}

export const connectEmployeeFormRules = {
  roles: {
    type: 'array',
    message: 'Выберите как минимум одну роль',
    required: true,
  },
}

export const employeeRoles = [
  {
    name: 'Владелец магазина',
    value: 'merchant_owner',
  },
  {
    name: 'Менеджер по работе с заказами',
    value: 'merchant_order_manager',
  },
  {
    name: 'Складовщик',
    value: 'merchant_stock_manager',
  },
  {
    name: 'Бухгалтер',
    value: 'merchant_accountant',
  },
]
export const documentFormRules = {
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  link: [
    {
      type: 'string',
      message: 'Выберите документ',
      required: true,
    },
    {
      pattern: /^(ftp|http|https):\/\/[^ "]+$/,
      message: 'Невалидная ссылка',
    },
  ],
}
export const documentsColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '200px',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Название документа',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Операции',
    dataIndex: 'link',
    key: 'operations',
    width: '200px',
    scopedSlots: { customRender: 'operations' },
  },
]
