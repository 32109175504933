<template>
  <div>
    <a-form-model
      ref="form"
      :model="formState"
      :rules="validationRules"
      @submit="onValidate"
    >
      <a-form-model-item label="Загрузить из JSON">
        <input type="file" @change="previewFiles" />
      </a-form-model-item>
      <div class="bg-gray">
        <a-divider>Атрибуты товара</a-divider>

        <div v-for="(item, mainIndex) in localAttrListState" :key="mainIndex">
          <div :class="{ 'reqField-box': item.is_required }">
            <h4 v-if="item.is_required" style="color: darkred">
              Объязательное поле*
            </h4>
            <h3>{{ item.title.ru }}</h3>
            <p>{{ item.description.ru }}</p>

            <template v-if="item.has_options">
              <attribute-selector
                :attribute-info="item"
                :options-list="item.options"
                v-model="formState[item.slug]"
              />
            </template>

            <template v-else-if="item.is_collection">
              <div
                v-for="(atr, index) in item.values"
                :key="(index + 9).toString(36) + index"
              >
                <a-form-model-item
                  label="Значение атрибута"
                  :prop="`${item.slug}.${index}`"
                >
                  <a-textarea
                    allow-clear
                    v-if="item.type === 'multiline'"
                    v-model="formState[item.slug][index]"
                    :auto-size="{ minRows: 2, maxRows: 4 }"
                  />
                  <a-input-number
                    v-else-if="
                      item.type === 'integer' || item.type === 'decimal'
                    "
                    v-model="formState[item.slug][index]"
                    style="width: 100%"
                  />
                  <a-input v-else v-model="formState[item.slug][index]" />
                </a-form-model-item>
                <a-button
                  v-if="item.values.length > 1"
                  type="link"
                  @click="deleteAtrHandler(mainIndex, index)"
                  block
                >
                  удалить
                </a-button>
              </div>
              <a-button type="primary" @click="addNewValToAttr(mainIndex)">
                Добавить еще
              </a-button>
            </template>

            <div v-else>
              <a-form-model-item :prop="item.slug" label="Значение атрибута">
                <a-textarea
                  allow-clear
                  v-if="item.type === 'multiline'"
                  v-model="formState[item.slug]"
                  :auto-size="{ minRows: 2, maxRows: 4 }"
                />
                <a-input-number
                  v-else-if="item.type === 'integer' || item.type === 'decimal'"
                  v-model="formState[item.slug]"
                  style="width: 100%"
                />
                <a-input v-else v-model="formState[item.slug]" />
              </a-form-model-item>
            </div>
          </div>

          <a-divider></a-divider>
        </div>
      </div>
    </a-form-model>
    <parse-info
      :info="unmappedObject"
      :attr-list-state-fullness="attrListStateFullness"
    ></parse-info>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'

import AttributeSelector from 'Product/components/AttributeSelector'
import { INTEGER, DECIMAL } from 'Core/modules/validators/numbers'
import { URL } from 'Core/modules/validators/url'
import { eventBus } from '@/main'
import ParseInfo from 'Product/components/ParseInfo'

export default {
  name: 'AttributesForm',
  components: {
    AttributeSelector,
    ParseInfo,
  },
  props: {
    attributeListState: {
      type: Array,
      required: true,
    },
    modelId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    modelId() {
      this.initialize()
    },
  },
  data() {
    return {
      localAttrListState: [],
      formState: [],
      validationRules: {},
      unmappedObject: {
        total: null,
        mapped: null,
        items: [],
      },
    }
  },
  computed: {
    attrListStateFullness() {
      const length = this.localAttrListState.length
      let fulfilled = 0
      Object.entries(this.formState).forEach((value) => {
        if (Array.isArray(value[1])) {
          if (value[1].length !== 0 && value[1][0]) {
            fulfilled++
          }
        } else if (value[1]) {
          fulfilled++
        }
      })
      return {
        length: length,
        fulfilled: fulfilled,
      }
    },
  },
  methods: {
    initialize() {
      this.setLocalAttrState()
      this.setValidationRules()
    },
    setValidationRules() {
      let rules = {}
      this.attributeListState.map((attr) => {
        if (attr.has_options) {
          if (attr.is_collection) {
            rules[attr.slug] = []
            rules[attr.slug][0] = {
              required: attr.is_required,
            }
          } else {
            rules[attr.slug] = {
              required: attr.is_required,
            }
          }
          // may be some rules comes soon
        } else if (attr.is_collection) {
          switch (attr.type) {
            case 'integer': {
              rules[attr.slug] = []
              rules[attr.slug][0] = [
                {
                  required: attr.is_required,
                  fullField: attr.title.ru,
                  type: 'number',
                },
                {
                  validator: INTEGER,
                  fullField: attr.title.ru,
                },
              ]
              break
            }
            case 'decimal': {
              rules[attr.slug] = []
              rules[attr.slug][0] = [
                {
                  required: attr.is_required,
                  fullField: attr.title.ru,
                  type: 'number',
                },
                {
                  validator: DECIMAL,
                  fullField: attr.title.ru,
                },
              ]
              break
            }
            case 'url': {
              rules[attr.slug] = []
              rules[attr.slug][0] = [
                {
                  required: attr.is_required,
                  fullField: attr.title.ru,
                },
                {
                  validator: URL,
                  fullField: attr.title.ru,
                },
              ]
              break
            }
            default: {
              rules[attr.slug] = []
              rules[attr.slug][0] = {
                required: attr.is_required,
              }
            }
          }
        } else {
          switch (attr.type) {
            case 'integer': {
              rules[attr.slug] = [
                {
                  required: attr.is_required,
                  fullField: attr.title.ru,
                  type: 'number',
                },
                {
                  validator: INTEGER,
                  fullField: attr.title.ru,
                },
              ]
              break
            }
            case 'decimal': {
              rules[attr.slug] = [
                {
                  required: attr.is_required,
                  fullField: attr.title.ru,
                  type: 'number',
                },
                {
                  validator: DECIMAL,
                  fullField: attr.title.ru,
                },
              ]
              break
            }
            case 'url': {
              rules[attr.slug] = [
                {
                  required: attr.is_required,
                  fullField: attr.title.ru,
                },
                {
                  validator: URL,
                  fullField: attr.title.ru,
                },
              ]
              break
            }
            default: {
              rules[attr.slug] = {
                required: attr.is_required,
              }
            }
          }
        }
      })
      this.validationRules = rules
    },
    setLocalAttrState() {
      this.localAttrListState = [...this.attributeListState]
      let form = {}
      this.attributeListState.map((attr) => {
        if (attr.is_collection) {
          if (attr.has_options) {
            form[attr.slug] = attr.values.length ? attr.values : []
          } else {
            if (attr.values.length && attr.values[0].value !== '') {
              form[attr.slug] = []
              attr.values.map((val) => {
                form[attr.slug].push(val.value)
              })
            } else {
              form[attr.slug] = []
              form[attr.slug][0] = ''
            }
          }
        } else {
          if (attr.has_options) {
            form[attr.slug] = attr.values.length ? attr.values : []
          } else if (attr.values[0].value || attr.values[0].value === 0) {
            const tempVal =
              attr.type === 'integer' || attr.type === 'decimal'
                ? Number(attr.values[0].value)
                : attr.values[0].value
            form[attr.slug] = tempVal
          } else form[attr.slug] = ''
        }
      })
      this.formState = form
    },
    addNewValToAttr(index) {
      const curSlug = this.localAttrListState[index].slug
      const lastIndex = Number(Object.keys(this.formState[curSlug]).pop()) + 1
      this.formState[curSlug][lastIndex] = ''
      this.validationRules[curSlug][lastIndex] =
        this.validationRules[curSlug][lastIndex - 1]
      this.localAttrListState[index].values.push({ value: '' })
    },
    deleteAtrHandler(mainIndex, index) {
      const curSlug = this.localAttrListState[mainIndex].slug
      this.formState[curSlug].splice(index, 1)
      this.localAttrListState[mainIndex].values.splice(index, 1)
    },
    async onValidate() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            const attributes = this.formatAttrForPost(this.localAttrListState)
            this.$emit('createBaseProductHandler', attributes)
          } catch (error) {
            showErrorMsg(error.message)
          }
        } else {
          showErrorMsg('Заполните все поля')
          return false
        }
      })
    },
    formatAttrForPost(list) {
      let tempObj = []
      list.forEach((item) => {
        const curObj = {
          id: item.id,
          values: [],
        }
        if (
          item.is_collection &&
          (this.formState[item.slug][0] || this.formState[item.slug][0] === 0)
        ) {
          if (item.has_options) {
            this.formState[item.slug].map((val) => {
              curObj.values.push({
                value: item.options[val].value,
                slug: item.options[val].slug,
              })
            })
          } else {
            this.formState[item.slug].map((val) => {
              if (val) {
                curObj.values.push({
                  value: {
                    ru: val.toString(),
                    kz: val.toString(),
                  },
                })
              }
            })
          }
          tempObj.push(curObj)
        } else if (item.has_options) {
          if (
            this.formState[item.slug][0] ||
            this.formState[item.slug][0] === 0
          ) {
            const optionIndex = this.formState[item.slug][0]
            curObj.values[0] = {
              value: item.options[optionIndex].value,
              slug: item.options[optionIndex].slug,
            }
            tempObj.push(curObj)
          }
        } else if (
          this.formState[item.slug] &&
          this.formState[item.slug] !== '' &&
          !Array.isArray(this.formState[item.slug])
        ) {
          curObj.values[0] = {
            value: {
              ru: this.formState[item.slug].toString(),
              kz: this.formState[item.slug].toString(),
            },
          }
          tempObj.push(curObj)
        }
      })
      return tempObj
    },
    async previewFiles(event) {
      const file = event.target.files[0]
      let reader = new FileReader()
      const _this = this
      try {
        reader.readAsText(file)
        reader.onload = function () {
          _this.setParsedAttr(JSON.parse(reader.result))
        }
      } catch (error) {
        showErrorMsg('Упс, попробуйте обновить страницу')
      }
    },
    sanitaizeString(str) {
      return str
        .replace(/\s/g, '')
        .replace(/[,()'":?]/g, '')
        .toLowerCase()
    },
    checkForEquality(val_1, val_2) {
      return this.sanitaizeString(val_1) == this.sanitaizeString(val_2)
    },
    setParsedAttr(parsedInfo) {
      let counter = 0
      const len = this.localAttrListState.length
      for (let i = 0; i < len; i++) {
        this.localAttrListState[i].values = this.localAttrListState[i]
          .has_options
          ? []
          : [{ value: '' }]
      }
      for (let attr in parsedInfo.name) {
        const foundAttrIndex = this.localAttrListState.findIndex((item) =>
          this.checkForEquality(item.title.ru, attr),
        )
        if (foundAttrIndex !== -1) {
          counter++
          if (
            this.localAttrListState[foundAttrIndex].has_options === false &&
            this.localAttrListState[foundAttrIndex].is_collection === false
          ) {
            const is_number =
              this.localAttrListState[foundAttrIndex].type === 'integer' ||
              this.localAttrListState[foundAttrIndex].type === 'decimal'
            this.formState[this.localAttrListState[foundAttrIndex].slug] =
              is_number ? Number(parsedInfo.name[attr]) : parsedInfo.name[attr]
          } else {
            if (
              this.attributeListState[foundAttrIndex].is_collection &&
              this.localAttrListState[foundAttrIndex].has_options
            ) {
              const tempArr = parsedInfo.name[attr].split(',')
              tempArr.forEach((parsedOption) => {
                const optionIndex = this.attributeListState[
                  foundAttrIndex
                ].options.findIndex((item) =>
                  this.checkForEquality(item.value.ru, parsedOption),
                )
                if (optionIndex !== -1) {
                  this.formState[
                    this.localAttrListState[foundAttrIndex].slug
                  ].push(optionIndex)
                }
              })
            } else if (this.attributeListState[foundAttrIndex].is_collection) {
              const tempArr = parsedInfo.name[attr].split(',')
              tempArr.forEach((parsedOption, index) => {
                index === 0
                  ? (this.formState[
                      this.localAttrListState[foundAttrIndex].slug
                    ][0] = parsedOption)
                  : this.formState[
                      this.localAttrListState[foundAttrIndex].slug
                    ].push(parsedOption)
              })
            } else {
              const IndexWithComas = this.attributeListState[
                foundAttrIndex
              ].options.findIndex((item) => {
                return this.checkForEquality(
                  item.value.ru,
                  parsedInfo.name[attr],
                )
              })
              if (IndexWithComas !== -1) {
                this.formState[
                  this.localAttrListState[foundAttrIndex].slug
                ].push(IndexWithComas)
              }
            }
          }
        } else {
          this.unmappedObject.items.push({
            name: attr,
            value: parsedInfo.name[attr],
          })
        }
      }
      this.unmappedObject.total = Object.keys(parsedInfo.name).length
      this.unmappedObject.mapped = counter
    },
  },
  mounted() {
    eventBus.$on('attributes:validate', () => {
      this.onValidate()
    })
    this.initialize()
  },
  beforeDestroy() {
    eventBus.$off('attributes:validate')
  },
  created() {
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.bg-gray {
  padding: 10px;
  background-color: #f3f3f3;
  border: 1px solid #f3f3d9;
  border-radius: 5px;
}
</style>
