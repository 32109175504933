import { ALPHA } from 'Core/modules/validators/alpha'
import { INTEGER } from 'Core/modules/validators/numbers'
import { PHONE } from 'Core/modules/validators/phone'

export const formState = {
  address: {
    apartment: null,
    city_id: '750000000', //Almaty
    floor: 0,
    geo: {
      latitude: 0.0,
      longitude: 0.0,
    },
    house: null,
    street: null,
    zipcode: null,
  },
  contact: {
    info: null,
    phone: null,
  },
  name: null,
  priority: 1,
  schedule: [
    { weekday: 1, time_ranges: [] },
    { weekday: 2, time_ranges: [] },
    { weekday: 3, time_ranges: [] },
    { weekday: 4, time_ranges: [] },
    { weekday: 5, time_ranges: [] },
    { weekday: 6, time_ranges: [] },
    { weekday: 7, time_ranges: [] },
  ],
}
export const createRules = {
  name: [
    {
      required: true,
      fullField: 'Название',
      max: 50,
    },
    {
      fullField: 'Название',
      validator: ALPHA,
      langs: ['en', 'ru'],
      numeric: true,
      specials: ['space', '.', ',', "'", '"', ':', '»', '«'],
    },
  ],
  priority: [
    {
      type: 'number',
      required: true,
      fullField: 'Приоритет',
    },
    {
      validator: INTEGER,
      fullField: 'Приоритет',
    },
  ],
  contact: {
    info: [
      {
        fullField: 'Имя контакта',
        required: true,
        max: 50,
      },
      {
        fullField: 'Имя контакта',
        validator: ALPHA,
        langs: ['ru', 'kz', 'en'],
        specials: ['space', '-'],
      },
    ],
    phone: [
      {
        fullField: 'Номер контакта',
        required: true,
      },
      {
        fullField: 'Номер контакта',
        validator: PHONE,
      },
    ],
  },
  address: {
    apartment: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    floor: {
      type: 'number',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    house: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    street: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    zipcode: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    geo: {
      latitude: {
        type: 'string',
        message: 'Поле обязательно для заполнения',
        required: true,
      },
      longitude: {
        type: 'string',
        message: 'Поле обязательно для заполнения',
        required: true,
      },
    },
  },
}
const timeStamp = /[0-2][0-9](:[0-5][0-9])/
export const editRules = {
  name: [
    {
      required: true,
      fullField: 'Название',
      max: 50,
    },
    {
      validator: ALPHA,
      fullField: 'Название',
      langs: ['en', 'ru'],
      numeric: true,
      specials: ['space', '.', ',', "'", '"', ':', '»', '«'],
    },
  ],
  priority: [
    {
      type: 'number',
      required: true,
      fullField: 'Приоритет',
    },
    {
      validator: INTEGER,
      fullField: 'Приоритет',
    },
  ],
  contact: {
    info: [
      {
        fullField: 'Имя контакта',
        required: true,
        max: 50,
      },
      {
        fullField: 'Имя контакта',
        validator: ALPHA,
        langs: ['ru', 'kz', 'en'],
        specials: ['space', '-'],
      },
    ],
    phone: [
      {
        fullField: 'Номер контакта',
        required: true,
      },
      {
        fullField: 'Номер контакта',
        validator: PHONE,
      },
    ],
  },
  address: {
    apartment: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    floor: {
      type: 'number',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    house: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    street: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    zipcode: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    geo: {
      latitude: {
        type: 'string',
        message: 'Поле обязательно для заполнения',
        required: true,
      },
      longitude: {
        type: 'string',
        message: 'Поле обязательно для заполнения',
        required: true,
      },
    },
  },
  schedule: [
    {
      time_range: [
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
      ],
    },
    {
      time_range: [
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
      ],
    },
    {
      time_range: [
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
      ],
    },
    {
      time_range: [
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
      ],
    },
    {
      time_range: [
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
      ],
    },
    {
      time_range: [
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
      ],
    },
    {
      time_range: [
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
        {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          pattern: timeStamp,
        },
      ],
    },
  ],
}

export const weekDays = {
  0: 'Понедельник',
  1: 'Вторник',
  2: 'Среда',
  3: 'Четверг',
  4: 'Пятница',
  5: 'Суббота',
  6: 'Воскресенье',
}
