var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v("Список способов связи")]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:({
        service: 'banner',
        permissions: ['banner-create'],
      }),expression:"{\n        service: 'banner',\n        permissions: ['banner-create'],\n      }"}],attrs:{"to":{ name: 'ConfigContactCreate' }}},[_c('a-button',[_vm._v("Создать")])],1)],1),_c('a-table',{attrs:{"columns":_vm.contactColumns,"data-source":_vm.contacts,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
            var id = ref.id;

            return id;
},"loading":_vm.isLoading,"bordered":""},scopedSlots:_vm._u([{key:"contact_id",fn:function(contact_id){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'ConfigContactEdit',
          params: { contact_id: contact_id },
        }}},[_vm._v(" "+_vm._s(contact_id)+" ")])],1)}},{key:"contact_name",fn:function(contact_name){return _c('div',{},[_vm._v(" "+_vm._s(contact_name)+" ")])}},{key:"value",fn:function(value){return _c('div',{},[_vm._v(" "+_vm._s(value)+" ")])}},{key:"comment",fn:function(comment){return _c('div',{},[_vm._v(" "+_vm._s(comment)+" ")])}},{key:"type",fn:function(type){return _c('div',{},[_vm._v(" "+_vm._s(_vm.getReadableType(type))+" ")])}},{key:"priority",fn:function(priority, contact){return _c('div',{},[_c('a-input-number',{attrs:{"id":contact.id,"value":priority,"min":0},on:{"change":function (value) { return _vm.onPriorityChange(value, contact); }}}),_c('a-button',{staticClass:"form__button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.confirmPriorityChange(contact)}}},[_c('a-icon',{attrs:{"type":"save"}})],1)],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }