<template>
  <a-modal
    :visible="isVisible"
    title="Добавление нового сотрудника"
    width="45vw"
    :closable="false"
  >
    <a-form-model
      ref="searchForm"
      :rules="employeeSearchFormRules"
      :model="searchForm"
    >
      <phone-input
        placeholder="Введите номер телефона сотрудника"
        :label="'Номер телефона сотрудника'"
        :prop="'phone'"
        v-model="searchForm.phone"
      />
      <a-form-model-item>
        <a-button @click="onSearch" html-type="submit" type="primary">
          Поиск
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <a-spin :spinning="searchUserStatus == 'pending'">
      <div class="user-info__list" v-if="user && searchUserStatus == 'success'">
        <div class="user-info__item">
          <p class="user-info__label">ФИО:</p>
          <p class="user-info__text">
            {{ user.last_name }} {{ user.first_name }} {{ user.patronymic }}
          </p>
        </div>
        <div class="user-info__item">
          <p class="user-info__label">Статус:</p>
          <p class="user-info__text">
            {{ user.is_enabled ? 'Активен' : 'Неактивен' }}
          </p>
        </div>
        <div class="user-info__item">
          <p class="user-info__label">Роли сотрудника</p>
          <div class="user-info__text">
            <a-form-model
              ref="connectEmployeeForm"
              :rules="connectEmployeeFormRules"
              :model="connectEmployeeForm"
            >
              <a-form-model-item prop="roles">
                <a-select
                  mode="multiple"
                  v-model="connectEmployeeForm.roles"
                  placeholder="Выберите роли для сотрудника"
                >
                  <a-select-option
                    v-for="(role, index) in employeeRoles"
                    :key="index"
                    :value="role.value"
                  >
                    {{ role.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
      <h4 v-else-if="searchUserStatus == 'success'">Пользователь не найден</h4>
    </a-spin>
    <template slot="footer">
      <a-button key="back" @click="fail"> Отмена</a-button>
      <a-button
        type="primary"
        :loading="isConnectLoading"
        @click="onConnectEmployee"
        :disabled="!user"
      >
        Добавить
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import {
  employeeSearchFormRules,
  connectEmployeeFormRules,
  employeeRoles,
} from 'Partner/pages/Partner/PartnerInfo/constants'
import { MASK } from 'Core/constants'
import { showErrorMsg, cleanNumber, showSuccessMsg } from 'Core/utils'

import { searchUserAPI } from 'User/api/users'

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    fail: {
      type: Function,
      required: true,
    },
    success: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      MASK,
      employeeSearchFormRules,
      connectEmployeeFormRules,
      employeeRoles,
      searchForm: {
        phone: null,
      },
      connectEmployeeForm: {
        roles: [],
        tdid: null,
      },
      user: null,
      searchUserStatus: 'idle',
      isConnectLoading: false,
    }
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  methods: {
    async onSearch() {
      this.$refs.searchForm.validate(async (result) => {
        if (result) {
          try {
            this.searchUserStatus = 'pending'
            const { Users } = await searchUserAPI(
              cleanNumber(this.searchForm.phone),
            )
            if (Users.length) {
              this.user = Users[0]
            }
            this.searchUserStatus = 'success'
          } catch (error) {
            this.searchUserStatus = 'error'
            showErrorMsg('Не удалось найти сотрудника')
          }
        }
      })
    },
    async onConnectEmployee() {
      this.$refs.connectEmployeeForm.validate(async (result) => {
        if (result) {
          try {
            this.isConnectLoading = true
            this.connectEmployeeForm.tdid = this.user.id
            await this.$API.MerchantProfile.connectEmployeeToMerchant(
              this.merchantID,
              this.connectEmployeeForm,
            )
            showSuccessMsg('Сотрудник успешно добавлен')
            this.success()
          } catch (error) {
            showErrorMsg(error.message)
            this.fail()
          } finally {
            this.user = null
            this.searchForm.phone = null
            this.connectEmployeeForm = { roles: [], tdid: null }
            this.searchUserStatus = 'idle'
            this.isConnectLoading = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-info {
  &__item {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 15px;
  }

  &__label {
    font-weight: 600;
  }
}
</style>
