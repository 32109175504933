<template>
  <div>
    <a-spin :spinning="isFormLoading">
      <div class="settings-wrap">
        <a-button @click="() => $router.push({ name: 'BaseProductList' })">
          {{ $t('common.back') }}
        </a-button>
        <h2>Редактирование Базового продукта</h2>
        <a-popconfirm
          title="Дублировать продукт？"
          ok-text="Да, дублировать"
          cancel-text="Нет"
          @confirm="duplicateBaseProduct"
        >
          <a-button
            v-can="{
              service: 'storefront',
              permissions: ['base-product-create'],
            }"
          >
            Дублировать продукт
          </a-button>
        </a-popconfirm>
      </div>

      <a-form-model
        ref="form"
        :model="formState"
        :rules="rules"
        @submit="onUpdate"
      >
        <a-tabs default-active-key="1" tab-position="right" v-model="activeKey">
          <a-tab-pane key="1" tab="Основная информация">
            <a-form-model-item label="SKU">
              <a-input v-model="formState.sku" disabled></a-input>
            </a-form-model-item>
            <a-form-model-item prop="title.ru" label="Название">
              <a-input v-model="formState.title.ru" />
            </a-form-model-item>
            <a-form-model-item label="Категория">
              <a-input v-model="categoriesView" disabled></a-input>
            </a-form-model-item>
            <category-selector
              :category-parent="categoryParent"
              @setCategory="setCategory"
            />
            <a-row type="flex" align="bottom">
              <a-col flex="230px">
                <a-form-model-item prop="model_id" label="Модель">
                  <a-input v-model="modelState.title.ru" disabled></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>

            <template v-if="attributeListState.length">
              <attributes-form
                :attribute-list-state="attributeListState"
                :model-id="formState.model_id"
                @createBaseProductHandler="createModelAttributeGroupHandler"
              />
            </template>
            <a-row type="flex" align="bottom">
              <a-col flex="300px">
                <a-form-model-item label="Бренд" prop="brand">
                  <a-input v-model="formState.brand.title.ru" disabled>
                  </a-input>
                </a-form-model-item>
              </a-col>
              <a-col>
                <brand-selector @setBrandHandler="setBrandHandler" />
              </a-col>
            </a-row>
            <a-form-model-item label="Цвет" prop="color">
              <a-select v-model="formState.color" style="width: 100%">
                <a-select-option
                  v-for="{ title, slug, hex } in colorList"
                  :key="slug"
                  :value="slug"
                >
                  <div class="color-label">
                    <div
                      class="color-label-box"
                      :style="{ backgroundColor: hex }"
                    ></div>
                    <div>{{ title.ru }}</div>
                  </div>
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              prop="price.minimum"
              label="Минимальная допустимая цена"
            >
              <a-input-number
                style="width: 150px"
                v-model="formState.price.minimum"
                :min="100"
                disabled
              />
            </a-form-model-item>
            <template v-if="formState.type === 'REGULAR'">
              <a-divider>Параметры</a-divider>
              <div v-for="(item, index) in formState.dimensions" :key="index">
                <div class="dimension box-wrap">
                  <a-divider>
                    Размер коробки(в мм)
                    <b v-if="formState.dimensions.length > 1">
                      №{{ index + 1 }}
                    </b>
                  </a-divider>
                  <a-row :gutter="16">
                    <a-col :span="5">
                      <a-form-model-item
                        :prop="`dimensions[${index}].package.depth`"
                        label="Глубина(мм)"
                      >
                        <a-input-number v-model="item.package.depth" :min="1" />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="5">
                      <a-form-model-item
                        :prop="`dimensions[${index}].package.height`"
                        label="Высота(мм)"
                      >
                        <a-input-number
                          v-model="item.package.height"
                          :min="1"
                        />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="5">
                      <a-form-model-item
                        :prop="`dimensions[${index}].package.width`"
                        label="Ширина(мм)"
                      >
                        <a-input-number v-model="item.package.width" :min="1" />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="5">
                      <a-form-model-item
                        :prop="`dimensions[${index}].weight`"
                        label="Вес товара(г)"
                      >
                        <a-input-number v-model="item.weight" :min="1" />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="4">
                      <a-button
                        v-if="formState.dimensions.length > 1"
                        type="danger"
                        shape="circle"
                        @click="deleteBoxHandler(index)"
                      >
                        <a-icon type="close" />
                      </a-button>
                    </a-col>
                  </a-row>
                </div>
              </div>
              <a-form-item>
                <a-button @click="addBoxHandler">
                  Добавить еще параметр
                </a-button>
              </a-form-item>
            </template>
          </a-tab-pane>
          <!--          <a-tab-pane key="2" tab="Доп. информация">-->
          <!--            {{ formState.about_page }}-->
          <!--          </a-tab-pane>-->
          <a-tab-pane key="3" tab="Картинки">
            <product-media-tab
              :media="formState.media ? formState.media : []"
            ></product-media-tab>
          </a-tab-pane>
        </a-tabs>

        <a-form-item v-if="activeKey < 3">
          <a-button type="primary" html-type="submit">
            Сохранить изминения
          </a-button>
        </a-form-item>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { formState, rules } from './constants'
import { filterOption } from 'Core/utils'

import BrandSelector from 'Product/components/BrandSelector'
import ProductMediaTab from 'Product/components/baseProduct/tabs/ProductMediaTab'
import CategorySelector from 'Product/components/CategorySelector'

import AttributesForm from 'Product/components/AttributesForm'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { eventBus } from '@/main'

export default {
  name: 'BaseProductEdit',
  components: {
    CategorySelector,
    BrandSelector,
    ProductMediaTab,
    AttributesForm,
  },
  data: () => ({
    categoryParent: [],
    activeKey: '1',
    formState,
    rules,
    attributeListState: [],
    isFormLoading: true,
    categoryState: [],
    modelState: {
      title: {
        ru: 'Обновляется',
      },
    },
    categoriesView: '',
    colorList: [],
  }),
  watch: {
    $route: function () {
      if (this.$route.query.tab) {
        this.activeKey = this.$route.query.tab
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.query.tab) {
        vm.activeKey = to.query.tab
      }
    })
  },
  methods: {
    filterOption,
    async initialize() {
      try {
        this.isFormLoading = true
        await this.getBaseProductById()
        this.categoryParent = await this.$API.Storefront.getCategoryList({
          level: 1,
        })
        this.colorList = await this.$API.ConfigDiscovery.getColorList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFormLoading = false
      }
    },
    setAboutPage(params) {
      this.formState.about_page.ru = params.ru
      this.formState.about_page.kz = params.kz
    },
    setBrandHandler(brand) {
      this.formState.brand = brand
    },
    async getBaseProductById() {
      try {
        this.formState = await this.$API.Storefront.getBaseProductById(
          this.baseProductId,
        )
        this.formState.color = this.formState.color.slug
        this.categoryState = this.formState.categories
        // eslint-disable-next-line max-len
        this.categoriesView = `${this.formState.categories[0].title.ru} > ${this.formState.categories[1].title.ru} > ${this.formState.categories[2].title.ru}`
        this.modelState = await this.$API.Storefront.getModelById(
          this.formState.model_id,
        )
        await this.getModelInfo(this.formState.model_id)
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    setCategory(category) {
      this.categoryState = category
      this.categoriesView = `${category[0].title.ru} > ${category[1].title.ru} > ${category[2].title.ru}`
    },
    onSetModel(selectedModel) {
      this.formState.model_id = selectedModel.id
      this.modelState = selectedModel
      this.getModelInfo(selectedModel.id)
    },

    async getModelInfo(id) {
      const data = await this.$API.Storefront.getModelAttributeList(id)
      data.sort((a, b) => {
        return b.is_required - a.is_required
      })
      this.attributeListState = data.map((item) => {
        if (this.formState.attributes) {
          const atrElem = this.formState.attributes.find(
            ({ id }) => id === item.id,
          )
          if (atrElem) {
            let tempObj = {
              values: [],
            }
            if (item.has_options) {
              if (item.is_collection) {
                atrElem.values.forEach((el) => {
                  const tempVal = item.options.findIndex(
                    ({ slug }) => slug == el.slug,
                  )
                  tempObj.values.push(tempVal)
                })
              } else {
                const tempVal = item.options.findIndex(
                  ({ slug }) => slug == atrElem.values[0].slug,
                )
                tempObj.values[0] = tempVal
              }
            } else {
              atrElem.values.forEach((el) => {
                tempObj.values.push({ value: el.value.ru })
              })
            }
            return {
              ...Object.freeze(item),
              ...tempObj,
            }
          }
        }

        let tempObj = {
          values: item.has_options ? [] : [{ value: '' }],
        }
        return {
          ...Object.freeze(item),
          ...tempObj,
        }
      })
    },
    onUpdate(event) {
      event.preventDefault()
      this.validateFields()
    },
    validateFields() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            eventBus.$emit('attributes:validate')
          } catch (error) {
            showErrorMsg(error.message)
          }
        } else {
          showErrorMsg('Заполните все поля')
          return false
        }
      })
    },
    async createModelAttributeGroupHandler(list) {
      this.isFormLoading = true
      const tempObj = {
        title: {
          ru: this.formState.title.ru,
          kz: this.formState.title.ru,
        },
        // about: this.formState.about,
        // about_page: this.formState.about_page,
        model_id: this.formState.model_id,
        color: this.colorList.find(
          (item) => item.slug === this.formState.color,
        ),
        type: this.formState.type,
        dimensions: this.formState.dimensions,
        categories: this.categoryState,
        brand: this.formState.brand,
        attributes: list,
      }
      try {
        await this.$API.Storefront.updateBaseProductById(
          this.baseProductId,
          tempObj,
        )
        showSuccessMsg('Удачно Сохранен!')
        this.isFormLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFormLoading = false
      }
    },
    addBoxHandler() {
      this.formState.dimensions.push({
        package: {
          depth: 1,
          height: 1,
          width: 1,
        },
        weight: 1,
      })
      this.rules.dimensions.push({
        package: {
          depth: {
            type: 'number',
            message: 'Поле обязательно для заполнения',
            required: true,
          },
          height: {
            type: 'number',
            message: 'Поле обязательно для заполнения',
            required: true,
          },
          width: {
            type: 'number',
            message: 'Поле обязательно для заполнения',
            required: true,
          },
        },
        weight: {
          type: 'number',
          message: 'Поле обязательно для заполнения',
          required: true,
        },
      })
    },
    deleteBoxHandler(index) {
      this.formState.dimensions.splice(index, 1)
      this.rules.dimensions.splice(index, 1)
      this.$refs.form.clearValidate()
    },
    async duplicateBaseProduct() {
      try {
        const { sku } = await this.$API.Storefront.createDuplicateBaseProduct(
          this.baseProductId,
        )
        await this.$router.push(`/base-product/${sku}`)
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  computed: {
    baseProductId() {
      return this.$route.params.id
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.dimension {
  padding: 5px 30px;
  border-bottom: 2px solid #f3f3f3;
  margin-bottom: 10px;
}

.box-wrap {
  background-color: lightgray;
}

.bg-gray {
  padding: 10px;
  background-color: #f3f3f3;
  border: 1px solid #f3f3d9;
  border-radius: 5px;
}

.color-label {
  display: flex;

  &-box {
    width: 25px;
    height: 25px;
    margin-right: 7px;
  }
}

.reqField-box {
  padding: 20px;
  background-color: #ffcfcf;
}
</style>
