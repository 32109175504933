<template>
  <div>
    <a-spin :spinning="isFetching">
      <a-row type="flex" justify="center">
        <a-col span="20">
          <a-row class="mb-24" type="flex" justify="space-between">
            <a-col>
              <h2>Редактирование атрибута</h2>
            </a-col>
            <a-col>
              <a-button
                @click="
                  () =>
                    $router.push({
                      name: 'AttributeList',
                      params: { id: modelId },
                    })
                "
              >
                {{ $t('common.back') }}
              </a-button>
            </a-col>
          </a-row>
          <a-form-model
            ref="form"
            :model="formState"
            :rules="rules"
            @submit="onUpdate"
          >
            <a-form-model-item label="Slug(генерируется автоматически)">
              <a-input v-model="formState.slug" disabled />
            </a-form-model-item>
            <a-form-model-item
              prop="description.ru"
              label="Описание атрибута (RU)"
            >
              <a-input v-model="formState.description.ru" />
            </a-form-model-item>
            <a-form-model-item
              prop="description.kz"
              label="Описание атрибута (KZ)"
            >
              <a-input v-model="formState.description.kz" />
            </a-form-model-item>
            <a-row type="flex" justify="space-between">
              <a-form-item label="У атрибута несколько значении">
                <a-switch
                  :disabled="isInUse"
                  v-model="formState.is_collection"
                />
              </a-form-item>
              <a-form-item label="В short description">
                <a-switch
                  :disabled="!formState.is_required"
                  v-model="formState.is_description"
                />
              </a-form-item>
              <a-form-item label="Обязательный атрибут">
                <a-switch :disabled="isInUse" v-model="formState.is_required" />
              </a-form-item>
              <a-form-item label="Вариативный атрибут">
                <a-switch
                  :disabled="!formState.is_required"
                  v-model="formState.is_variable"
                />
              </a-form-item>
            </a-row>
            <a-form-model-item prop="group_id" label="Группа">
              <a-select v-model="formState.group_id">
                <a-select-option
                  v-for="(group, index) in groupList"
                  :value="group.id"
                  :key="index"
                >
                  {{ group.title.ru }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="title.ru" label="Название атрибута (RU)">
              <a-input v-model="formState.title.ru" />
            </a-form-model-item>
            <a-form-model-item prop="title.kz" label="Название атрибута (KZ)">
              <a-input v-model="formState.title.kz" />
            </a-form-model-item>

            <a-form-model-item label="Тип значения">
              <a-tag>{{ getTypeName(formState.type) }}</a-tag>
            </a-form-model-item>

            <a-form-model-item
              prop="weight"
              label="Вес атрибута (для приоритетности)"
            >
              <a-input-number v-model="formState.weight" :min="0" />
            </a-form-model-item>

            <a-form-item label="Значение атрибута выбирается из списка">
              <a-switch :disabled="isInUse" v-model="formState.has_options" />
            </a-form-item>
            <template v-if="formState.has_options">
              <a-divider>Заполните возможные опции</a-divider>
              <div v-for="(item, index) in optionsState" :key="index">
                <a-form-model-item
                  label="Описание опции для контент-менеджера(RU)"
                >
                  <a-input v-model="item.description" :disabled="isInUse" />
                </a-form-model-item>
                <a-row :gutter="16">
                  <a-col :span="10">
                    <a-form-model-item label="Наименование опции (RU)">
                      <a-input v-model="item.value.ru" :disabled="isInUse" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="10">
                    <a-form-model-item label="Наименование опции (KZ)">
                      <a-input v-model="item.value.kz" :disabled="isInUse" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="2">
                    <a-button
                      v-if="optionsState.length > 1 && !isInUse"
                      type="danger"
                      shape="circle"
                      @click="deleteOptionByIndex(index)"
                    >
                      <a-icon type="close" />
                    </a-button>
                  </a-col>
                </a-row>
                <a-divider></a-divider>
              </div>
              <a-form-model-item v-if="!isInUse">
                <a-button type="link" @click="enlargeOptions()">
                  Добавить еще
                </a-button>
              </a-form-model-item>
              <a-button
                style="margin-bottom: 20px"
                v-else
                @click="addOptionModalShow"
              >
                Добавить еще опцию
              </a-button>
            </template>

            <a-form-item>
              <a-button-group>
                <a-button type="primary" html-type="submit">Сохранить</a-button>
                <a-popconfirm
                  v-if="!isInUse"
                  title="Удалить безвозвратно?"
                  ok-text="Да, удалить"
                  cancel-text="отмена"
                  @confirm="() => onDelete()"
                >
                  <a-button type="danger">
                    <a-icon type="delete" />
                  </a-button>
                </a-popconfirm>
              </a-button-group>
            </a-form-item>
          </a-form-model>
        </a-col>
      </a-row>
    </a-spin>
    <a-modal
      v-model="addOptionModal"
      title="Добавление новой опции"
      cancel-text="Отмена"
      ok-text="Добавить"
      @ok="addOptionHandler"
      :ok-button-props="{
        props: { disabled: !(newOption.value.ru && newOption.value.kz) },
      }"
    >
      <a-form-model-item label="Описание опции для контент-менеджера">
        <a-input v-model="newOption.description" />
      </a-form-model-item>
      <a-form-model-item label="Наименование опции (RU)">
        <a-input v-model="newOption.value.ru" />
      </a-form-model-item>
      <a-form-model-item label="Наименование опции (KZ)">
        <a-input v-model="newOption.value.kz" />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import { formState, rules, optionsState } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'AttributeEdit',
  data: () => ({
    formState,
    rules,
    optionsState,
    isFetching: true,
    groupList: [],
    isInUse: false,
    addOptionModal: false,
    newOption: {
      description: '',
      value: {
        ru: '',
        kz: '',
      },
    },
  }),
  methods: {
    async addOptionHandler() {
      try {
        this.isFetching = true
        await this.$API.Storefront.enlargeModelAttributeOption(
          this.modelId,
          this.attributeId,
          this.newOption,
        )
        await this.initialize()
        showSuccessMsg('Успешно добавили опцию')
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.addOptionModal = false
      }
    },
    addOptionModalShow() {
      this.newOption = {
        description: '',
        value: {
          ru: '',
          kz: '',
        },
      }
      this.addOptionModal = true
    },
    enlargeOptions() {
      this.optionsState.push({
        description: null,
        value: {
          ru: null,
          kz: null,
        },
      })
    },
    getTypeName(type) {
      switch (type) {
        case 'string': {
          return 'Текстовое поле'
        }
        case 'integer': {
          return 'Целые числа'
        }
        case 'decimal': {
          return 'Дробные числа'
        }
        case 'url': {
          return 'Ссылка'
        }
        case 'multiline': {
          return 'Строка в несколько строк'
        }
        default: {
          return type
        }
      }
    },
    async initialize() {
      try {
        this.isFetching = true
        this.isInUse = !!(
          await this.$API.Storefront.getModelDependencyCount(this.modelId)
        ).count
        await this.getAttributeById()
        this.groupList = await this.$API.Storefront.getModelAttributeGroupList(
          this.modelId,
        )
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getAttributeById() {
      this.formState = await this.$API.Storefront.getAttributeById(
        this.modelId,
        this.attributeId,
      )
      if (this.formState.has_options) {
        this.optionsState = this.formState.options
      }
      this.isFetching = false
    },
    onUpdate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        this.isFetching = true
        await this.updateAttributeHandler()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    deleteOptionByIndex(index) {
      this.optionsState.splice(index, 1)
    },
    async updateAttributeHandler() {
      let formObjState = {}
      if (this.formState.has_options) {
        formObjState = { ...this.formState, options: this.optionsState }
      } else {
        let { ...reformated } = this.formState
        formObjState = reformated
      }

      if (this.isInUse) {
        await this.updateAttributeIsUseById()
      } else
        await this.$API.Storefront.updateAttribute(
          this.modelId,
          this.attributeId,
          formObjState,
        ),
          alert('asd')
      await this.getAttributeById()
      showSuccessMsg('Удачно обновлен!')
    },
    async updateAttributeIsUseById() {
      try {
        this.isFetching = true
        const formObjState = {
          description: this.formState.description,
          group_id: this.formState.group_id,
          title: this.formState.title,
          weight: this.formState.weight,
          is_description: this.formState.is_description,
          is_variable: this.formState.is_variable,
        }
        await this.$API.Storefront.updateAttribute(
          this.modelId,
          this.attributeId,
          formObjState,
        )
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = true
      }
    },
    async onDelete() {
      await this.$API.Storefront.deleteModelAttributeById(
        this.modelId,
        this.attributeId,
      )
      showSuccessMsg('Атрибут удален.')
      await this.$router.push({
        name: 'AttributeList',
        params: { id: this.modelId },
      })
    },
  },
  mounted() {
    this.initialize()
  },
  computed: {
    attributeId() {
      return this.$route.params.id
    },
    modelId() {
      return this.$route.params.modelId
    },
  },
}
</script>

<style scoped></style>
