<template>
  <div>
    <a-button @click="visible = true">Добавить в категорию комиссию</a-button>
    <a-drawer
      title="Выберите категорию товара"
      width="50%"
      placement="right"
      :closable="false"
      :mask-closable="true"
      :keyboard="true"
      @close="onClose"
      :destroy-on-close="true"
      :visible="visible"
    >
      <div class="category-wrap" v-if="categoryIII.length">
        <div v-for="(item, index) in categoryIII" :key="index">
          <div class="cat-label" @click="showModal(item)">
            {{ item.title.ru }}
          </div>
        </div>
      </div>
      <div v-else>
        <h3>А категории то еще не залили =(</h3>
      </div>
      <div class="button-absolute-box">
        <a-button-group>
          <a-button type="primary"> Сохранить </a-button>
          <a-button @click="onClose"> Отмена</a-button>
        </a-button-group>
      </div>
    </a-drawer>
    <a-modal
      v-model="modalIsShown"
      title="Укажите значение от 0-100%"
      cancel-text="Отмена"
      @ok="handleSaveCategoryRate"
      :ok-button-props="{
        props: { disabled: !rate },
      }"
    >
      <h4 v-if="currentCategory.title">
        {{ currentCategory.title.ru }}
      </h4>
      <a-form-model-item label="Сумма комиссии">
        <a-input-number
          v-model="rate"
          :min="0"
          block
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          :max="100"
        />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'CategorySelector',
  props: {
    categoryIII: {
      type: Array,
      required: true,
    },
    merchantID: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    visible: false,
    modalIsShown: false,
    rate: 5,
    currentCategory: {},
  }),
  methods: {
    onClose() {
      this.visible = false
    },
    async handleSaveCategoryRate() {
      try {
        const params = {
          value: this.rate,
          category_title: {
            ru: this.currentCategory.title.ru,
            kz: this.currentCategory.title.ru,
          },
          category: this.currentCategory.slug,
          merchant_id: this.merchantID,
        }
        await this.$API.Commissions.createCustomCommission(params)
        showSuccessMsg('Вы добавили глобальную комиссию')
        this.visible = false
        this.modalIsShown = false
        this.$emit('UpdateCommissionList')
      } catch (err) {
        showErrorMsg(err.message)
      } finally {
        this.visible = false
        this.modalIsShown = false
      }
    },
    showModal(category) {
      this.currentCategory = category
      this.modalIsShown = true
    },
  },
}
</script>

<style scoped lang="scss">
.category {
  &-wrap {
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 20px;
    max-height: 60vh;
    overflow: auto;
  }
}

.cat-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 20px;
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 2px solid blanchedalmond;

  &:hover {
    color: #73be6f;
  }
}
</style>
