<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание способа связи</h2>
      <router-link :to="{ name: 'ConfigContactList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-spin :spinning="isFormLoading">
      <a-form-model
        ref="form"
        :model="contactForm"
        :rules="contactFormRules"
        @submit="createContact"
      >
        <a-form-model-item prop="name" label="Название">
          <a-input v-model="contactForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="comment" label="Комментарий">
          <a-textarea
            allow-clear
            v-model="contactForm.comment"
            :auto-size="{ minRows: 2, maxRows: 4 }"
          />
        </a-form-model-item>
        <a-form-model-item prop="type" label="Тип способа связи">
          <a-select
            @select="onSelectType"
            :value="contactForm.type"
            placeholder="Выберите тип способа связи"
          >
            <a-select-option
              v-for="type in contactTypes"
              :key="type"
              :value="type"
            >
              {{ getReadableType(type) }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="value" :label="getValueLabel">
          <a-input v-model="contactForm.value" />
        </a-form-model-item>
        <a-form-model-item prop="icon_url" label="Иконка">
          <upload-files
            @getImageParams="getImageParams"
            :img-url="contactForm.icon_url"
            placeholder-text="Загрузить иконку"
          />
        </a-form-model-item>
        <a-form-model-item prop="priority" label="Приоритет в списке">
          <a-input-number v-model="contactForm.priority" :min="1" />
        </a-form-model-item>
        <a-button
          type="primary"
          html-type="submit"
          class="form__button"
          :loading="isСreateLoading"
          v-can="{
            service: 'banner',
            permissions: ['banner-update'],
          }"
          >Сохранить
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { contactFormRules } from '../ConfigContactEdit/constants'
import UploadFiles from 'Core/components/Files/UploadFiles.vue'

export default {
  components: {
    UploadFiles,
  },
  data() {
    return {
      contactFormRules,
      isСreateLoading: false,
      isFormLoading: false,
      contactForm: {
        name: null,
        value: null,
        comments: null,
        type: null,
        priority: 1,
      },
      contactTypes: null,
    }
  },
  created() {
    this.getContactTypes()
  },
  methods: {
    onSelectType(type) {
      this.contactForm.type = type
      if (this.contactForm.type == 'email') {
        this.contactFormRules.value.push({
          type: 'email',
          message: 'Введите валидный email адрес!',
        })
      } else {
        this.contactFormRules.value = this.contactFormRules.value.filter(
          (rule) => rule.type !== 'email',
        )
      }
    },
    getValueLabel() {
      switch (this.contactForm.type) {
        case 'email':
          return 'Адрес эл.почты'
        case 'phone':
          return 'Номер телефона'
        case 'social_network':
          return 'Ссылка'
        default:
          return 'Значение'
      }
    },
    getReadableType(type) {
      switch (type) {
        case 'email':
          return 'Эл. почта'
        case 'phone':
          return 'Телефон'
        case 'social_network':
          return 'Соц.сеть'
        default:
          return type
      }
    },
    getImageParams(params) {
      this.contactForm.icon_url = params
    },
    async getContactTypes() {
      try {
        this.isFormLoading = true
        const { types } = await this.$API.ConfigDiscovery.getContactTypes()
        this.contactTypes = types
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFormLoading = false
      }
    },
    async createContact(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isСreateLoading = true
            await this.$API.ConfigDiscovery.createContact(this.contactForm)
            showSuccessMsg('Способ связи успешно создан!')
            this.$router.push({ name: 'ConfigContactList' })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isСreateLoading = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.form__button {
  margin-top: 30px !important;

  &--danger {
    margin-left: 20px;
  }
}
</style>
